import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import * as dataStore from './firestore';
import { Services } from 'services/servicesInterfaces';
import { Licence, Company } from 'services/dataStoreInterfaces';

if (window.location.hostname === "localhost") {
    console.log("[FUNCTIONS]: DEBUG MODE!");
    firebase
        .functions()
        .useFunctionsEmulator('http://localhost:5001');
}

export const inviteUser:Services.inviteUser = firebase.functions().httpsCallable('inviteUser');
export const resendInvite:Services.resendInvite = firebase.functions().httpsCallable('resendInvite');
export const createCompany = firebase.functions().httpsCallable('createCompany');
export const disableUser = firebase.functions().httpsCallable('disableUser');
export const deleteUser = firebase.functions().httpsCallable('deleteUser');
export const updateUsersLicence:Services.updateUsersLicence = firebase.functions().httpsCallable('updateUsersLicence');
export const updateUserRoles = firebase.functions().httpsCallable('updateUserRoles');
export const sendContactSupportRequest = firebase.functions().httpsCallable('sendContactSupportRequest');
export const updateUser = async (uid:string, data) => {
    const promisesToResolve = [] as Promise<any>[];
    // update groups
    if(data.groups !== undefined){
        const userGroupsRef = dataStore.getUserRef(data.companyId, uid).collection('access').doc('groups');
        
        
        const batch = firebase.firestore().batch();
        // TODO: remove later when there can be many groups 
        batch.set(userGroupsRef, {});

        data.groups.forEach(group => batch.set(userGroupsRef, {[group.id]: group}, {merge: true}))
        promisesToResolve.push(batch.commit());
            updateUserRoles({
                uid, 
                companyId: data.companyId,
                roles: {[data.groups[0].name]: true}
            }).catch((err) => {console.log(err);})
    }

    // update models
    if(data.models !== undefined){
        const userModelsRef = dataStore.getUserRef(data.companyId, uid).collection('access').doc('models');
        
        const batch = firebase.firestore().batch();
        // TODO: remove later when there can be many groups 
        batch.set(userModelsRef, {});

        data.models.forEach(model => batch.set(userModelsRef, {[model.id]: model}, {merge: true}))
        promisesToResolve.push(batch.commit());
    }

    return Promise.all(promisesToResolve)
}

export const addCompany:Services.addCompany = async (data) => {
    return await dataStore.createCompany(data);
}

export const addLicence:Services.addLicence = async ({companyId, name, users, renewAt}) => {
    const licenceId = await dataStore.createLicence({companyId, name, users, renewAt});
    const usersLicenceUpdateData = users.reduce<Services.updateUsersLicenceProps[]>((acc, uid) => {
        acc.push({
            uid,
            renewAt: renewAt.toUTCString(),
            licenceId,
            companyId
        })
        return acc;
    }, []);
    await updateUsersLicence(usersLicenceUpdateData);
    return licenceId;
}

export const updateLicense:Services.updateLicence = async ({ licenseId, companyId, name, users, renewAt }) => {
    let usersLicenceUpdateData = [] as Services.updateUsersLicenceProps[];
    if(users.length){
        usersLicenceUpdateData = users.reduce<Services.updateUsersLicenceProps[]>((acc, uid) => {
            acc.push({
                uid,
                renewAt: renewAt.toUTCString(),
                licenceId: licenseId,
                companyId
            })
            return acc;
        }, []);
    }else {
        const licence = await dataStore.getLicenceData(companyId, licenseId);
        usersLicenceUpdateData = licence.users.reduce<Services.updateUsersLicenceProps[]>((acc, uid) => {
            acc.push({
                uid,
                companyId,
                licenceId: null,
            })
            return acc;
        }, []);
    }
    await dataStore.updateLicence({ licenseId, companyId, name, users, renewAt });
    await updateUsersLicence(usersLicenceUpdateData);
    return licenseId;
}

export const deleteLicence = async (companyId:Company.Id, id:Licence.id) => {
    const licence = await dataStore.getLicenceData(companyId, id);
    const usersLicenceUpdateData = licence.users.reduce<Services.updateUsersLicenceProps[]>((acc, uid) => {
        acc.push({
            uid,
            companyId,
            licenceId: null,
        })
        return acc;
    }, []);
    updateUsersLicence(usersLicenceUpdateData);
    dataStore.deleteLicence(companyId, id);
}