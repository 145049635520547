import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AppIcon from '../app-icon/index';
import classnames from 'classnames';
import Actor from '../actor';

import './index.css';
import { IAppState } from '../../reducers';

export default function ActorsPanel() {
    const [collapsed, setCollapsed] = useState(false);
    const className = classnames('actors-pane-container', { collapsed });

    const toggleCollapsed = () => setCollapsed(!collapsed);
    const actors = useSelector(({ capabilities }: IAppState) => capabilities.actors);
    const selectedScenario = useSelector(({ scenarios }: IAppState) => scenarios.selected);
    const selectedScenarioData = useSelector(({ scenarios }: IAppState) => scenarios.list.find(_ => _.scenarioID === scenarios.selectedId));
    const isScenarioActive = useSelector(({ scenarios }: IAppState) => scenarios.active);
    const overlayAll = (selectedScenarioData && selectedScenarioData.overlayAll) || false;

    const actorsFilteredByScenario = () => {
        if (isScenarioActive && !overlayAll) {
            return actors.filter(actor => selectedScenario?.actorIds!.indexOf(actor.capabilityID) > -1)
        }
        return actors;
    }

    const internalActors = actorsFilteredByScenario()
        .filter(actor => actor.type === 'Internal')
        .map(actor => <Actor key={actor.capabilityID} actor={actor}/>);

    const externalActors = actorsFilteredByScenario()
        .filter(actor => actor.type === 'External')
        .map(actor => <Actor key={actor.capabilityID} actor={actor}/>);

    return (
        <div className={className}>
            <div className='actors-container'>
                <div className='shift-pane'>
                    <div className='title'>INTERNAL ACTORS</div>
                </div>
                {internalActors}
                <div className='shift-pane'>
                    <div className='title'>EXTERNAL ACTORS</div>
                </div>
                {externalActors}
            </div>
            <button className='button' onClick={toggleCollapsed}>
                <AppIcon iconName={collapsed ? 'arrow_right' : 'arrow_left'} />
            </button>
        </div>
    )
};
