import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './index.css';

import AppIcon from '../app-icon/index';
import { showCapabilityModal } from '../../actions/modals';
import { ICapability } from '../../interfaces';
import CollapsedContainerHeader from '../collapsed-container-header';

export interface ICapabilityTreeStructure {
    capability: ICapability;
    childrenTree?: ICapabilityTreeStructure[]
}

interface IProps {
    capabilityTree: ICapabilityTreeStructure;
    secondary?: boolean;
}

export default function CapabilityTree({ capabilityTree, secondary }: IProps) {
    const dispatch = useDispatch();
    const [showSubBranches, setShowSubBranches] = useState(false);

    const showCapabilityFromBranch = () => dispatch(showCapabilityModal(capabilityTree.capability));
    const childrenTrees = capabilityTree.childrenTree || [];
    const children = childrenTrees.map(capabilityTree => 
        <CapabilityTree capabilityTree={capabilityTree} secondary key={JSON.stringify(capabilityTree)}/>);

    const hasSubBranches = !!children.length
    const branchesAmount = hasSubBranches ? ` (${children.length})` : '';
    const rootClassName = `capability-tree ${!secondary ? 'root' : 'branch'}`;
    return (
        <div className={rootClassName}>
            <CollapsedContainerHeader
                label={`${capabilityTree.capability.name}${branchesAmount}`}
                onClick={() => setShowSubBranches(!showSubBranches)}
                isCollapseIconVisible={hasSubBranches}
                isCollapsed={!showSubBranches}
                renderMoreButtonSelections={() => {
                    return <div className='selection-item' key={'key'} data-id={0}>
                        <div className='label' onClick={showCapabilityFromBranch}>{'Go to capability'}</div>
                        <AppIcon iconName='arrow_right' className='arrow-icon' />
                    </div>
                }}
                onMoreButtonSelectionsChanged={() => { }}
            />
            <div className='branches'>
                {(showSubBranches && hasSubBranches) && children}
            </div>
        </div>
    )
}

