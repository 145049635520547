import { useContext, useEffect } from 'react';
import { DataContext } from 'App';
import { dataService } from 'services';
import { DataStore, Company } from 'services/dataStoreInterfaces';

let licencesCache:DataStore.LicenceStoreDataWithId[] = [];

const useLicencesSubscription = (companyIds:Company.Id[]) => {
    const {licences, setLicences} = useContext(DataContext)

    useEffect(() => {
        const unsubscribeFuncs = companyIds.map(companyId => {
            return dataService.subscribeAllCompanyLicences(companyId, (changes) => {

                !!changes.removed && Object.keys(changes.removed).forEach(id => {
                    licencesCache = licencesCache.filter(res => res.id !== id);
                });
                (!!changes.added) && Object.entries(changes.added).forEach(([id, value]) => {
                    const index = licencesCache.findIndex(res => res.id === id);
                    const data = {...value, createdAt: value.createdAt.toDate(), renewAt: value.renewAt.toDate(), id};
                    index > -1 
                        ? licencesCache.splice(index, 1, data)
                        : licencesCache.splice(0, 0, data)
                    licencesCache = [...licencesCache];
                });
                (!!changes.modified) && Object.entries(changes.modified).forEach(([id, value]) => {
                    const index = licencesCache.findIndex(res => res.id === id);
                    licencesCache.splice(index, 1, {...value, createdAt: value.createdAt.toDate(), renewAt: value.renewAt.toDate(), id});
                    licencesCache = [...licencesCache];
                });
                setLicences(licencesCache);
            })
        });
        return () => unsubscribeFuncs.forEach(func => func());
    }, [companyIds, setLicences]);
    
    return licences;
};

export default useLicencesSubscription;