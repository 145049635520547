import {ADMIN as adminActionTypes} from './admin';
import {GROUPS as groupsActionTypes} from './groups';
export enum APP {
    INIT = 'APP__INIT',
    RESET_EVERYTHING = 'APP__RESET_EVERYTHING',
    RESET_EVERYTHING_FILE = "RESET_EVERYTHING_FILE"
}

export enum USER {
    MODELS_CHANGED = 'MODELS_CHANGED',
    SUBSCRIBE_MODEL_UPDATES = 'SUBSCRIBE_MODEL_UPDATES'
}

export enum MODEL {
    UPDATE_MODEL_INFO = 'UPDATE_MODEL_INFO',
    UPLOAD_MODEL = 'UPLOAD_MODEL'
}

export enum AUTH {
    CHECK_USER_AUTH = 'AUTH__CHECK_USER_AUTH',
    REQUEST_USER_AUTH = 'AUTH__REQUEST_USER_AUTH',
    SIGN_IN_REQUEST = 'AUTH__SIGN_IN_REQUEST',
    SIGN_UP_REQUEST = 'AUTH__SIGN_UP_REQUEST',
    // SIGN_IN_RESPONSE = 'AUTH__SIGN_IN_RESPONSE',
    SIGN_OUT_REQUEST = 'AUTH__SIGN_OUT_REQUEST',
    // SIGN_OUT_RESPONSE = 'AUTH__SIGN_OUT_RESPONSE',
    SIGN_OUT = 'AUTH__SIGN_OUT'
}

export enum SIGN_IN {
    SIGN_IN_REQUEST = 'SIGN__IN_REQUEST',
    SIGN_UP_REQUEST = 'SIGN__UP_REQUEST',
    SIGN_IN_USER_NAME_CHANGED = 'SIGN__IN_USER_NAME_CHANGED',
    SIGN_IN_COMPANY_NAME_CHANGED = 'SIGN__IN_COMPANY_NAME_CHANGED',
    SIGN_IN_EMAIL_CHANGED = 'SIGN__IN_EMAIL_CHANGED',
    SIGN_IN_PASSWORD_CHANGED = 'SIGN__IN_PASSWORD_CHANGED',
}

export const ADMIN = adminActionTypes;
export const GROUPS = groupsActionTypes;