import { SCENARIOS } from "../constants/scenarios";
import { APP } from "../constants/action-types";
import flatten from 'lodash/flatten';
import { IScenario, IScenarioStep } from "../interfaces";
import { IScenarioActions } from "../actions/scenarios";
import { IActionAppResetFile } from "../actions/auth";

export interface IScenariosState {
    list: IScenario[]
    active: boolean
    selectedId?: string
    selected: {
        scenario?: IScenario,
        step?: IScenarioStep,
        capabilityIds?: number[],
        actorIds?: number[]
    }
}

export const defaultState: IScenariosState = {
    list: [],
    active: false,
    selected: {
        scenario: undefined,
        step: undefined,
        capabilityIds: [],
        actorIds: []
    }
};

export default (state: IScenariosState = defaultState, action: IScenarioActions | IActionAppResetFile): IScenariosState => {
    switch (action.type) {
        case APP.RESET_EVERYTHING_FILE: 
            return defaultState;
        case SCENARIOS.SELECT_SCENARIO:
            const { scenario } = action.payload;
            const step = action.payload.step || scenario.steps[0];
            const stepIndex = scenario.steps.indexOf(step);
            const allStepsBefore = scenario.steps.slice(0, stepIndex + 1);
            const actorIds = flatten(allStepsBefore.map(step => [step.actor1ID, step.actor2ID])).filter(Boolean);
            const capabilityIds = allStepsBefore.map(step => step.capabilityID);

            return {
                ...state,
                active: true,
                selectedId: scenario.scenarioID,
                selected: {
                    scenario,
                    step,
                    actorIds,
                    capabilityIds
                }
            }
        case SCENARIOS.OVERLAY_ALL_CAPABILITIES:
            return {
                ...state,
                list: state.list.map(scenario => {
                    if(scenario.scenarioID === action.payload.scenarioId) {
                        return {...scenario, overlayAll: action.payload.state}
                    }
                    return scenario;
                })
            }
        case SCENARIOS.RECEIVED_SCENARIOS:
            return {
                ...state,
                list: action.payload.scenarios.map(scenario => ({...scenario, overlayAll: false}))
            }
        case SCENARIOS.RESET_SELECTION:
            return {
                ...state,
                active: false,
                selected: {}
            }
        default:
            return state;
    }
};