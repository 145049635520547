import { FilterLabels } from "./utils/filterUtils";

declare global {
    interface Window { 
        firebaseConfig: any; 
        prevDispatch: any;
        prevGroups: any;
        globalCache: any;
    }
}

export interface IWithNameAndDescription {
    name: string;
    description: string;
}

export interface IWithKey {
    key: number;
}

export interface IWithParent {
    parentID: number;
    parentName: string;
}

export interface IScenarioStep extends IWithNameAndDescription, IWithKey {
    actor1: string;
    actor1ID: number;
    actor2: string;
    actor2ID: number;
    capability: string;
    capabilityID: number;
    scenarioID: number;
    scenarioUID: string;
    stepDescription: string;
    stepID: number;
    stepName: string;
    type: string;
}

export interface IScenario extends IWithNameAndDescription {
    scenarioID: string;
    overlayAll?: boolean;
    steps: IScenarioStep[];
}

export interface IFilter {
    label: FilterLabels,
    selections: string[],
    selectionId: number,
    // defaultSelection: string,
    unselectable?: boolean;
}

export enum CapabilityType {
    CORE = 'Core',
    ENABLING = 'Enabling'
}

export interface ICapability extends IWithNameAndDescription, IWithKey, IWithParent {
    capabilityID: number;
    capabilityUID: string;
    cost: number;
    element: 'Actor' | 'Capability';
    level: number;
    nameInView: string;
    shortName: string;
    fullName: string;
    type: 'Core' | 'Enabling'
}

export enum CustomerSegmentType {
    TOP_LEVEL = 'Top-level',
    SEGMENT = 'Segment'
}

export interface ICustomerSegment extends IWithNameAndDescription, IWithKey, IWithParent {
    customerSegmentID: 1
    customerSegmentUID: string;
    type: CustomerSegmentType
}

export interface IActor extends IWithNameAndDescription, IWithKey, IWithParent {
    capabilityID: number;
    capabilityUID: string;
    cost: number;
    element: string;
    level: number;
    nameInView: string;
    shortName: string;
    type: string;
}

export enum ViewType {
    CAPABILITY = 'Capability',
    SYSTEM = 'System',
    TEAM = 'Team',
    ISSUE = 'Issue',
    RISK = 'Risk',
    CONTROL = 'Control',
    POLICY = 'Policy',
    DATA = 'Data',
    PROCESS = 'Process',
    SERVICE = 'Service',
    DASHBOARD = 'Dashboard',
    PROJECT = 'Project',
}

export interface ViewTypeBaseStructure {
    key: number;
    name: string;
    fullName: string;
    description: string;
}

export enum MetricType {
    MATURITY = "Maturity",
    COST = "Cost",
    NUM_TEAMS = "Num Teams",
    NUM_PEOPLE = "Num People",
    NUM_LOCATIONS = "Num Locations",
    NUM_SYSTEMS = "Num Systems",
    NUM_PROJECTS = "Num Projects",
    EFFICIENCY = "Efficiency",
    FATE = "Fate",
    CLOUD_READINESS = "Cloud Readiness",
    USERS = "Users",
    NUM_ISSUES = "Num Issues",
    SEVERITY = "Severity",
    LIKELIHOOD = "Likelihood",
    RATING = "Rating",
    EFFECTIVENESS = "Effectiveness",
    COMPLIANCE = "Compliance",
    NUM_DATA_ELEMENTS = "Num Data Elements",
    QUALITY = "Quality",
    ALIGNMENT = "Alignment",
    PHASE = "Phase",
    FUNDING = "Funding",
    STATUS = "Status",
}

export interface IRAWIconColors {
    fillColour: String;
    fontColour: String;
}

export interface IColorItem extends IRAWIconColors{
    colourName: string;
    fillColour: string;
    fontColour: string;
    colour: string;
}

export enum VisualTypes {
    singleMetric = 'singleMetric',
    metricGroup = 'metricGroup', 
    metricGroupColour = 'metricGroupColour', 
    costTrend = 'costTrend'
};

export interface IView {
    key: string;
    L1ID: number;
    L1Name: string;
    L1Type: ViewType
    capID: number;
    capType: ViewType
    capability: string;
    colour: string;
    colourLabel: string;
    fillColour: string;
    fontColour: string;
    metricSubType: MetricType
    metricType: MetricType
    metricValue: string;
    state: string;
    stateID: number;
    viewMetric: string;
    viewSubject: string;
    viewSubjectID: number;
    viewType: ViewType
    locationID: number;
    organisationID: number;
    productID: number;
    customerSegmentID: number;
    type: string;
    visualType: VisualTypes;
    commentary: string;
}

export interface IViewMetric extends IWithKey {
    L1: string;
    L2: string;
    METRIC: string;
    VALUE: string;
    THRESHOLD: string;
    COMMENTARY: string;
    'views.colour': string;
    'views.metricValue': string;
    'views.viewMetric': string;
}

export interface IAbstracViewEntity extends IWithNameAndDescription, IWithKey {}

export interface ISystem extends IAbstracViewEntity {
    fullName: string;
    nameInView: string;
    owner: string;
    systemID: number;
    systemUID: string;
    totalCostOwnership: number;
    type: string;
}

export interface ITeam extends IAbstracViewEntity {
    fullName: string;   
    teamID: number;
    teamUID: string;
    totalCost: number;
    type: string;
}

export enum LocationType {
    TOP_LEVEL = 'Top-level',
    REGION = 'Region',
    COUNTRY = 'Country'
}

export interface ILocation extends IAbstracViewEntity, IWithParent {
    locationID: number;
    locationUID: string;
    type: LocationType
}

export enum OrganizationType {
    TOP_LEVEL = 'Top-level',
    BUSINESS = 'Business',
    LINE_OF_BUSINESS = 'Line of Business'
}

export interface IOrganization extends IAbstracViewEntity, IWithParent {
    organisationID: number;
    organisationUID: string;
    type: OrganizationType
}

export enum ProductType {
    TOP_LEVEL = 'Top-level',
    FAMILY = 'Family',
    ASSET_CLASS = 'Asset class',
    PRODUCT = 'Product'
}

export interface IProduct extends IAbstracViewEntity, IWithParent {
    productID: number;
    productUID: string;
    type: ProductType
}

export enum StateType {
    TOP_LEVEL = 'Top-level',
    CURRENT = 'Current',
    INTERIM = 'Interim',
    TARGET = 'Target'
}

export interface IState extends IAbstracViewEntity, IWithParent {
    stateDate: number;
    stateID: number;
    stateUID: string;
    type: StateType
}

export interface IModelData {
    modelName: string;
    supportEmail: string;
    initialView: ViewType;
    initialMetric: MetricType;
}