import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { Company, Model } from 'services/dataStoreInterfaces';

export const CONSTANTS = {
    FIREBASE_STORAGE_COMPANIES_ROOT: 'companies',
    FIREBASE_STORAGE_MODELS_ROOT: 'models',
}

const generateModelPath = (cid, modelId, version='latest') => 
    `${CONSTANTS.FIREBASE_STORAGE_COMPANIES_ROOT}/${cid}/${CONSTANTS.FIREBASE_STORAGE_MODELS_ROOT}/${modelId}/modelVersions/${version}`;
const generateMetricPath = (cid, modelId, metricId) => 
    `${CONSTANTS.FIREBASE_STORAGE_COMPANIES_ROOT}/${cid}/${CONSTANTS.FIREBASE_STORAGE_MODELS_ROOT}/${modelId}/metricVersions/${metricId}`;

interface IMetadata {
    id: string;
}

export const getDownloadURLFor = (path:string) => firebase.storage().ref(path).getDownloadURL()

export const uploadModel = async (cid: Company.Id, file: File, { id }: IMetadata) => {
    try {
        const storageRef = firebase.storage().ref();
        const modelPath = generateModelPath(cid, id);
        const fileRef = storageRef.child(modelPath);
        const snapshot = await fileRef.put(file);
        console.log(`File ${file.name} was successfully uploaded. Download URL: ${snapshot.ref.getDownloadURL()}`);
        return snapshot;
    }
    catch(err) {
        console.log(`Trying to upload file ${file.name}.`, err);
    }
}

export const uploadMetrics = async (cid: Company.Id, modelId:Model.id, metricId, file: File, { id }: IMetadata) => {
    try {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(generateMetricPath(cid, modelId, metricId));
        const snapshot = await fileRef.put(file);
        console.log(`Metric ${file.name} was successfully uploaded. Download URL: ${snapshot.ref.getDownloadURL()}`);
        return snapshot;
    }
    catch(err) {
        console.log(`Trying to upload file ${file.name}.`, err);
    }
}