import { FILTERS } from "../constants/filters";
import { IOrganization, ILocation, IState, IProduct, ICustomerSegment, IColorItem, ViewType, MetricType } from "../interfaces";
import { FilterLabels } from "../utils/filterUtils";
import { IAction } from "../reducers";

export interface IViewMetricItem {
    viewType: ViewType;
    metricType: string;
    default: string;
    metricValueType: string;
    metricValueFrom: string;
    metricValueTo: string;
    metricGroup: string;
    colour: string;
    metricSubType: string;
}

export interface IDefaultFilterSelection {
    view: ViewType;
    metric: MetricType;
}

export interface IFilterSelectionChangePayload {
    filterId: FilterLabels
    selectionId: number
}

export interface ISetViewMetricsMappingPayload {
    mapping: Record<string, IViewMetricItem[]>
}

export interface ISetLocationsFilterSourcePayload {
    locations: ILocation[]
}
export interface ISetStatesFilterSourcePayload {
    states: IState[]
}
export interface ISetProductFilterSourcePayload {
    products: IProduct[]
}
export interface ISetBusinessFilterSourcePayload {
    busineses: IOrganization[]
}
export interface ISetClientFilterSourcePayload {
    clients: ICustomerSegment[]
}

export type ISetColorsPayload = Record<string, IColorItem>;

export interface IActionFilterSelectionChanged extends IAction<typeof FILTERS.SELECTION_CHANGED, IFilterSelectionChangePayload> { }
export interface IActionCalculateMetricsFilterSource extends IAction<typeof FILTERS.CALCULATE_METRICS_FILTER_SOURCE> { }
export interface IActionResetAllFilters extends IAction<typeof FILTERS.RESET_ALL> { }
export interface IActionSetViewMetricsMapping extends IAction<typeof FILTERS.VIEW_METRICTS_MAPPING_SET, ISetViewMetricsMappingPayload> { }
export interface IActionSetDefaultFilterSelection extends IAction<typeof FILTERS.SET_DEFAULT_FILTER_SELECTION, IDefaultFilterSelection> { }
export interface IActionSetLocationsFilterSource extends IAction<typeof FILTERS.SET_LOCATIONS_FILTER_SOURCE, ISetLocationsFilterSourcePayload> { }
export interface IActionSetStatesFilterSource extends IAction<typeof FILTERS.SET_STATES_FILTER_SOURCE, ISetStatesFilterSourcePayload> { }
export interface IActionSetProductFilterSource extends IAction<typeof FILTERS.SET_PRODUCT_FILTER_SOURCE, ISetProductFilterSourcePayload> { }
export interface IActionSetBusinessFilterSource extends IAction<typeof FILTERS.SET_BUSINESS_FILTER_SOURCE, ISetBusinessFilterSourcePayload> { }
export interface IActionSetClientFilterSource extends IAction<typeof FILTERS.SET_CLIENT_FILTER_SOURCE, ISetClientFilterSourcePayload> { }
export interface IActionSetColors extends IAction<typeof FILTERS.SET_COLORS, ISetColorsPayload> { }

export const filterSelectionChanged = (filterId: FilterLabels, selectionId: number): IActionFilterSelectionChanged => ({
    type: FILTERS.SELECTION_CHANGED,
    payload: { filterId, selectionId }
})

export const calculateMetricsFilterSource = (): IActionCalculateMetricsFilterSource => ({
    type: FILTERS.CALCULATE_METRICS_FILTER_SOURCE,
    payload: { }
})

export const resetAllFilters = (): IActionResetAllFilters => ({
    type: FILTERS.RESET_ALL,
    payload: { }
})

export const setViewMetricsMapping = (mapping: Record<string, IViewMetricItem[]>): IActionSetViewMetricsMapping => ({
    type: FILTERS.VIEW_METRICTS_MAPPING_SET,
    payload: { mapping }
})

export const setDefaultFilterSelection = (defaultFilterSelection: IDefaultFilterSelection): IActionSetDefaultFilterSelection => ({
    type: FILTERS.SET_DEFAULT_FILTER_SELECTION,
    payload: defaultFilterSelection
})

export const setLocationsFilterSource = (locations: ILocation[]): IActionSetLocationsFilterSource => ({
    type: FILTERS.SET_LOCATIONS_FILTER_SOURCE,
    payload: { locations }
})

export const setStatesFilterSource = (states: IState[]): IActionSetStatesFilterSource => ({
    type: FILTERS.SET_STATES_FILTER_SOURCE,
    payload: { states }
})

export const setProductFilterSource = (products: IProduct[]): IActionSetProductFilterSource => ({
    type: FILTERS.SET_PRODUCT_FILTER_SOURCE,
    payload: { products }
})

export const setBusinessFilterSource = (busineses: IOrganization[]): IActionSetBusinessFilterSource => ({
    type: FILTERS.SET_BUSINESS_FILTER_SOURCE,
    payload: { busineses }
})

export const setClientFilterSource = (clients: ICustomerSegment[]): IActionSetClientFilterSource => ({
    type: FILTERS.SET_CLIENT_FILTER_SOURCE,
    payload: { clients }
})

export const setColors = (payload:ISetColorsPayload):IActionSetColors => ({
    type: FILTERS.SET_COLORS,
    payload
})

export type IFilterActions = IActionFilterSelectionChanged
    | IActionSetViewMetricsMapping
    | IActionCalculateMetricsFilterSource
    | IActionResetAllFilters
    | IActionSetLocationsFilterSource
    | IActionSetStatesFilterSource
    | IActionSetProductFilterSource
    | IActionSetBusinessFilterSource
    | IActionSetClientFilterSource
    | IActionSetColors
    | IActionSetDefaultFilterSelection