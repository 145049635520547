import { combineReducers, Reducer } from 'redux'
import { connectRouter, RouterState } from 'connected-react-router'

import auth, { IAuthState } from './auth'
import user, { IUserState } from './user'
import capabilities, { ICapabilitiesState } from './capabilities'
import filters, { IFiltersState } from './filters'
import modals, { IModalsState } from './modals'
import model, { IModelState } from './model'
import layout, { ILayoutState } from './layout'
import scenarios, { IScenariosState } from './scenarios'
import { History } from 'history'
import groups, { IGroupsState } from './groups'
import models, { IModelsState } from './models'
import { ActionStatus } from '../constants/action-statuses'
import admin, { IAdminState } from './admin'

export interface IAction<T extends string, P = {}> {
    type: T
    payload: P
}

// export interface IActionWithStatus<T, P> extends IAction<T, P>{
//     status: ActionStatus;
// }

export interface IActionWithStatus<T, P={}> {
    type: T;
    status: {
        isStarted: boolean;
        isSuccess: boolean;
        isFailed: boolean;
        original: ActionStatus;
    };
    payload: P;
}

export interface IAppState {
    router: Reducer<RouterState<{}>, any>,
    auth: IAuthState
    user: IUserState,
    capabilities: ICapabilitiesState,
    filters: IFiltersState,
    model: IModelState,
    modals: IModalsState,
    scenarios: IScenariosState,
    layout: ILayoutState,
    groups: IGroupsState,
    models: IModelsState,
    admin: IAdminState
}

const createRootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    auth,
    user,
    capabilities,
    filters,
    model,
    modals,
    scenarios,
    layout,
    groups,
    models,
    admin
});
export default createRootReducer;