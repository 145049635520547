import { CAPABILITIES } from "../constants/capabilities";
import { ICapability, IActor, IView, ISystem, ITeam, IViewMetric } from "../interfaces";
import { ICapabilitiesActions } from "../actions/capabilities";
import { APP } from "constants/action-types";
import { IActionAppReset, IActionAppResetFile } from "actions/auth";

export interface ICapabilitiesState {
    capabilities: ICapability[]
    system: ISystem[]
    teams: ITeam[]
    actors: IActor[]
    views: IView[]
}

export const defaultState: ICapabilitiesState = {
    capabilities: [],
    system: [],
    teams: [],
    actors: [],
    views: []
};

export default (state: ICapabilitiesState = defaultState, action: ICapabilitiesActions | IActionAppReset | IActionAppResetFile): ICapabilitiesState => {
    switch (action.type) {
        case CAPABILITIES.CAPABILITIES_RECEIVED:
            return { ...state, capabilities: action.payload.capabilities }
        case CAPABILITIES.SYSTEMS_RECEIVED:
            return { ...state, system: action.payload.systems }
        case CAPABILITIES.TEAMS_RECEIVED:
            return { ...state, teams: action.payload.teams }
        case CAPABILITIES.ACTORS_RECEIVED:
            return { ...state, actors: action.payload.actors }
        case CAPABILITIES.RECEIVED_VIEWS_DATA:
            return { ...state, views: action.payload.views }
        case CAPABILITIES.UPDATE_METRICS_DATA:
            const metricsMap: Record<string, IViewMetric> = action.payload.metrics.reduce((res, metric) => {
                res[metric.key] = metric;
                return res;
            }, {});
            const views = state.views.map(view => {
                if (!metricsMap[view.key]) {
                    return view;
                }

                const metric = metricsMap[view.key];

                const newView = {
                    ...view,
                    colour: metric["views.colour"],
                    metricValue: metric["views.metricValue"],
                    viewMetric: metric["views.viewMetric"],
                    colourLabel: metric["OVERRIDE COLOUR"] === 'None' ? view.colourLabel : metric["OVERRIDE COLOUR"],
                    commentary: metric.COMMENTARY ? metric.COMMENTARY.toString() : view.commentary
                } as IView;

                return newView;
            });

            return { ...state, views }
        case CAPABILITIES.RECEIVED_VIEW_TYPE_DATA:
            return { ...state, ...action.payload }
        case APP.RESET_EVERYTHING:    
        case APP.RESET_EVERYTHING_FILE:
            console.log('RESET_EVERYTHING_FILE');
            return defaultState;
        default:
            return state;
    }
};
