import React, { useEffect, useState } from 'react';
import './index.css';

interface IProps {
    name: string;
    email: string;
}

export default function UserLogo({ name, email }: IProps) {
    const [letters, setLetters] = useState('N/A');
    useEffect(() => {
        const value = name || email;
        const parsed = value.split(' ');
        if (parsed.length === 1) {
            setLetters(parsed[0].slice(0, 2).toUpperCase());
        } else {
            const letters = `${parsed[0][0]}${parsed[1][0]}`.toUpperCase();
            setLetters(letters)
        }
    }, [email, name]);
    return (
        <div className="user-logo">
            <span className='user-logo_letters'>{letters}</span>
        </div>
    );
};