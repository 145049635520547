import React from 'react';
import FilteredCapabilityLabels from '../filtered-capability-labels';

import './index.css';
import { showCapabilityModal } from '../../actions/modals';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import Tooltip from 'rc-tooltip';
import { IAppState } from '../../reducers';
import { ViewType, IView } from '../../interfaces';
import { makeGetCapabilityById } from '../../utils/selectors';
import FormattedText from '../formatted-text';

interface Props {
    type: ViewType;
    capabilityID: number;
    name: string;
    views: IView[];
}

export default function LevelTwoCapability({ type, capabilityID, name, views }: Props) {
    const dispatch = useDispatch();
    const state = useSelector((state: IAppState) => state);
    const scenarios = useSelector(({ scenarios }: IAppState) => scenarios);
    const selectedScenario = scenarios.selected;
    const isScenarioActive = scenarios.active;
    const selectedScenarioData = scenarios.list.find(
        _ => _.scenarioID === state.scenarios.selectedId,
    );
    const visibleCapabilities = selectedScenario.capabilityIds || [];
    const overlayAll = (selectedScenarioData && selectedScenarioData.overlayAll) || false;

    const getCapabilityById = makeGetCapabilityById();

    if (isScenarioActive && !overlayAll) {
        const shouldDisplay = visibleCapabilities.includes(capabilityID);
        if (!shouldDisplay) {
            return null;
        }
    }

    const onShowLevelTwoModal = () => {
        if (type === ViewType.CAPABILITY) {
            const capability = getCapabilityById(state, capabilityID);
            if (capability) {
                dispatch(showCapabilityModal(capability));
            }
        }
    };
    let description: string | undefined;
    if (type === ViewType.CAPABILITY) {
        const capability = getCapabilityById(state, capabilityID);
        description = capability?.description;
    }

    const isCapabilityWithinScenario = overlayAll && visibleCapabilities.includes(capabilityID);
    const isActive =
        isScenarioActive &&
        (selectedScenario?.step?.capabilityID === capabilityID || isCapabilityWithinScenario);

    const className = classnames('level-two-capability', { 'active-in-scenario': isActive });

    return (
        <div className="level-two-label_container">
            <div key={name} className={className} onClick={onShowLevelTwoModal}>
                <Tooltip
                    trigger={'hover'}
                    placement={'bottom'}
                    transitionName={'appear'}
                    mouseEnterDelay={1}
                    destroyTooltipOnHide
                    overlay={() => {
                        return description ? (
                            <div className="label-item-popover">
                                <div className="label-description">
                                    <FormattedText str={description} />
                                </div>
                            </div>
                        ) : (
                            <div />
                        );
                    }}
                >
                    <h3>{name}</h3>
                </Tooltip>
                <div className="capability-labels" onMouseOver={event => event.stopPropagation()}>
                    <FilteredCapabilityLabels views={views} />
                </div>
            </div>
        </div>
    );
}
