import React, { useEffect, useCallback } from 'react';

import './index.css';
import LicencesListItem from '../licences-list-item';
import TableHeader from 'components/table-header';
import useSortableData from 'hooks/useSortableData';
import { getCompaniesList } from 'actions/admin';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from 'reducers';
import useLicencesSubscription from 'hooks/useLicencesSubscribtion';
import { Company } from 'services/dataStoreInterfaces';

export default function LicencesList() {
    const companiesList = useSelector((state: IAppState) => state.admin.companiesList);
    const dispatch = useDispatch();

    const companiesIds = companiesList.map(company => company.id);
    const licences = useLicencesSubscription(companiesIds);
    const getCompanyName = useCallback(
        (companyId: Company.Id) => companiesList.find(company => company.id === companyId)?.name,
        [companiesList],
    );

    useEffect(() => {
        dispatch(getCompaniesList({}));
    }, [dispatch]);

    const { items, requestSort, sortConfig } = useSortableData(licences);

    return (
        <div className="licences-list-container">
            <div className="licences-list">
                <div></div>
                <TableHeader
                    name="name"
                    label="NAME"
                    sortConfig={sortConfig}
                    onClick={() => requestSort('name')}
                />
                <TableHeader
                    name="companyId"
                    label="COMPANY NAME"
                    sortConfig={sortConfig}
                    onClick={() => requestSort('companyId')}
                />
                <TableHeader
                    name="users"
                    label="# unique users"
                    sortConfig={sortConfig}
                    onClick={() => requestSort('users')}
                />
                <TableHeader
                    name="users"
                    label="# models"
                    sortConfig={sortConfig}
                    onClick={() => requestSort('users')}
                />
                <TableHeader
                    name="users"
                    label="# models / users"
                    sortConfig={sortConfig}
                    onClick={() => requestSort('users')}
                />
                <TableHeader
                    name="renewalDate"
                    label="RENEWAL DATE"
                    sortConfig={sortConfig}
                    onClick={() => requestSort('renewalDate')}
                />
                <TableHeader label="ACTION" />

                {items.map(licence => (
                    <LicencesListItem
                        key={licence.id}
                        uid={licence.id}
                        companyId={licence.companyId}
                        companyName={getCompanyName(licence.companyId)!}
                        name={licence.name}
                        users={licence.users}
                        renewalDate={licence.renewAt}
                    />
                ))}
            </div>
        </div>
    );
}
