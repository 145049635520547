import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from 'rc-tooltip';
import { showActorModal } from '../../actions/modals';
import classnames from 'classnames';

import './index.css';
import { IAppState } from '../../reducers';
import { IActor } from '../../interfaces';
import FormattedText from '../formatted-text';

interface Props {
    actor: IActor;
}

export default function Actor({ actor }: Props) {
    const dispatch = useDispatch();
    const scenarioStep = useSelector(({ scenarios }: IAppState) => scenarios.selected.step);
    const isScenarioActive = useSelector(({ scenarios }: IAppState) => scenarios.active);
    const selectedScenario = useSelector(({ scenarios }: IAppState) => scenarios.selected);
    const selectedScenarioData = useSelector(({ scenarios }: IAppState) =>
        scenarios.list.find(_ => _.scenarioID === scenarios.selectedId),
    );
    const overlayAll = (selectedScenarioData && selectedScenarioData.overlayAll) || false;

    const onActorClick = useCallback(() => {
        dispatch(showActorModal(actor));
    }, [dispatch, actor]);
    const actorId = actor.capabilityID;
    let isActive = false;
    if (isScenarioActive) {
        isActive = overlayAll
            ? !!selectedScenario.actorIds?.includes(actorId as any) // TODO: any
            : scenarioStep?.actor1ID === actorId || scenarioStep?.actor2ID === actorId;
    }
    const className = classnames('actor', { 'active-in-scenario': isActive });

    return (
        <Tooltip
            trigger={'hover'}
            placement={'bottom'}
            transitionName={'appear'}
            overlay={() => {
                return (
                    <div className="actor-item-popover">
                        <div className="label-full-name">{actor.name}</div>
                        <div className="label-description">
                            <FormattedText str={actor.description} />
                        </div>
                    </div>
                );
            }}
        >
            <div className={className} onClick={onActorClick}>
                {actor.name}
            </div>
        </Tooltip>
    );
}
