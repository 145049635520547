import { ModalActions } from '../constants/modals'
import { IActor, IView, ISystem, ICapability } from '../interfaces'
import { IGenericModalItem } from '../components/generic-modal'
import { IAction } from '../reducers'

export interface IShowCapabityModalPayload {
    capability: ICapability
}
export interface IShowSystemModalPayload {
    system: ISystem
    view: IView
}
export interface IShowActorModalPayload {
    actor: IActor
}
export interface IShowGeneralModalPayload {
    data: IGenericModalItem
    view: IView
}
export interface IShowContactSupportModalPayload {}
export interface IShowMetricsUploadModalPayload {
    uid: string;
    companyId: string;
    modelId: string;
}

export interface IShowEditUserModalPayload {
    uid: string;
}

export interface IShowEditLicenseModalPayload {
    uid: string;
    companyId: string;
}

export interface IShowUploadModelModalPayload {
    uid: string;
}


export interface IActionShowCapabilityModal extends IAction<typeof ModalActions.SHOW_CAPABILITY, IShowCapabityModalPayload> { }
export interface IActionShowSystemModal extends IAction<typeof ModalActions.SHOW_SYSTEM, IShowSystemModalPayload> { }
export interface IActionShowActorModal extends IAction<typeof ModalActions.SHOW_ACTOR, IShowActorModalPayload> { }
export interface IActionShowGeneralModal extends IAction<typeof ModalActions.SHOW_GENERAL, IShowGeneralModalPayload> { }
export interface IActionShowInviteUserModal extends IAction<typeof ModalActions.SHOW_INVITE_USER> { }
export interface IActionShowAddLicenceModal extends IAction<typeof ModalActions.SHOW_ADD_LICENCE> { }
export interface IActionShowAddCompanyModal extends IAction<typeof ModalActions.SHOW_ADD_COMPANY> { }
export interface IActionIShowUploadModal extends IAction<typeof ModalActions.SHOW_UPLOAD_MODAL, IShowUploadModelModalPayload> { }
export interface IActionIShowEditUserModal extends IAction<typeof ModalActions.SHOW_EDIT_USER_MODAL, IShowEditUserModalPayload> { }
export interface IActionIShowEditLicenseModal extends IAction<typeof ModalActions.SHOW_EDIT_LICENSE_MODAL, IShowEditLicenseModalPayload> { }
export interface IActionShowContactSupportModal extends IAction<typeof ModalActions.SHOW_CONTACT_SUPPORT, IShowContactSupportModalPayload> { }
export interface IActionShowMetricsUploadModal extends IAction<typeof ModalActions.SHOW_METRICS_UPLOAD, IShowMetricsUploadModalPayload> { }
export interface IActionHideModal extends IAction<typeof ModalActions.HIDE> { }

export const showCapabilityModal = (capability: ICapability): IActionShowCapabilityModal => ({
    type: ModalActions.SHOW_CAPABILITY,
    payload: {
        capability
    }
})

export const showSystemModal = (system: ISystem, view: IView): IActionShowSystemModal => ({
    type: ModalActions.SHOW_SYSTEM,
    payload: {
        system,
        view
    }
})

export const showActorModal = (actor: IActor): IActionShowActorModal => ({
    type: ModalActions.SHOW_ACTOR,
    payload: {
        actor
    }
})

export const showGeneralModal = (data: IGenericModalItem, view: IView): IActionShowGeneralModal => ({
    type: ModalActions.SHOW_GENERAL,
    payload: {
        data,
        view
    }
})

export const showInviteUserModal = (): IActionShowInviteUserModal => ({
    type: ModalActions.SHOW_INVITE_USER,
    payload: {}
})

export const showAddLicenceModal = (): IActionShowAddLicenceModal => ({
    type: ModalActions.SHOW_ADD_LICENCE,
    payload: {}
})

export const showAddCompanyModal = (): IActionShowAddCompanyModal => ({
    type: ModalActions.SHOW_ADD_COMPANY,
    payload: {}
})

export const showUploadModal = ({uid}): IActionIShowUploadModal => ({
    type: ModalActions.SHOW_UPLOAD_MODAL,
    payload: {uid}
})

export const showEditUserModal = ({uid}): IActionIShowEditUserModal => ({
    type: ModalActions.SHOW_EDIT_USER_MODAL,
    payload: {uid}
})

export const showEditLicenseModal = ({uid, companyId}): IActionIShowEditLicenseModal => ({
    type: ModalActions.SHOW_EDIT_LICENSE_MODAL,
    payload: {uid, companyId}
})

export const showContactSupportModal = (): IActionShowContactSupportModal => ({
    type: ModalActions.SHOW_CONTACT_SUPPORT,
    payload: {}
})
export const showMetricsUploadModal = ({uid, companyId, modelId}): IActionShowMetricsUploadModal => ({
    type: ModalActions.SHOW_METRICS_UPLOAD,
    payload: {uid, companyId, modelId}
})

export const hide = (): IActionHideModal => ({
    type: ModalActions.HIDE,
    payload: {}
})

export type IModalActions =
  | IActionShowCapabilityModal
  | IActionShowSystemModal
  | IActionShowActorModal
  | IActionShowGeneralModal
  | IActionShowInviteUserModal
  | IActionShowAddLicenceModal
  | IActionShowAddCompanyModal
  | IActionIShowUploadModal
  | IActionIShowEditUserModal
  | IActionHideModal
  | IActionIShowEditLicenseModal
  | IActionShowContactSupportModal
  | IActionShowMetricsUploadModal;
