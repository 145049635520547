import React from 'react';
import './index.css';
import { IActor } from '../../interfaces';
import FormattedText from '../formatted-text';

interface Props {
    actor: IActor
}

export default function ActorModal({ actor }: Props) {
    if (!actor) {
        return <div>actor not found</div>;
    }
    return (
        <div id='actor-modal'>
            <div className='overview-container'>
                <h2 className='title'>{actor.name}</h2> 
                <div className='definition-container'>
                    <h3>Definition</h3>
                    <div className='content'><FormattedText str={actor.description}/></div>
                </div>
            </div>
        </div>
    );
}

