import React, { SyntheticEvent } from 'react';
import classnames from 'classnames';

import './index.css';

interface Props {
    primary?: boolean
    onClick?: (event: SyntheticEvent) => void;
    type?: string
    disabled?: boolean
    secondary?: boolean
    tertiary?: boolean
    children: React.ReactNode
}

export default function Button({ primary, onClick, disabled, type, secondary, tertiary, children }: Props) {
    const classNames = classnames('button', {
        primary,
        secondary,
        tertiary
    });
    return (<button className={classNames} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    );
};
