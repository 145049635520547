import React, { useState, SyntheticEvent } from 'react';
import LevelThreeSubject from '../level-three-subject';

import './index.css';
import { IView } from '../../interfaces';
import { useSelector } from 'react-redux';
import { IAppState } from '../../reducers';
import { makeGetFilteredViews } from '../../utils/selectors';

interface Props {
    views: IView[]
}

const MAX_L3_LABELS_NUMBER = 40000; // it was 4 originaly 

export default function FilteredCapabilityLabels({ views }: Props) {
    const [takeItems, setTakeItems] = useState(MAX_L3_LABELS_NUMBER);
    const state = useSelector((state: IAppState) => state);

    const getFilteredViews = makeGetFilteredViews(views);
    const filteredViews = getFilteredViews(state);

    const labels = filteredViews.slice(0, takeItems).map((view, index) => {
        const { stateID, viewMetric } = view;
        // TODO: ask Kirill why we use index for the key calculations
        return <LevelThreeSubject
            key={`${index}-${stateID}-${view.viewSubjectID}-${viewMetric}`}
            view={view}
        ></LevelThreeSubject>
    });

    const showAll = (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setTakeItems(filteredViews.length)
    };
    const hasMore = filteredViews.length - takeItems;
    const more = hasMore > 0 ? <span className='more-button hoverable' onClick={showAll}>{hasMore} more</span> : null;

    return (
        <div className='system-labels'>
            {labels}
            {more}
        </div>
    )
}
