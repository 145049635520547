import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'

export class HTMLToPDF {
    doc: any;
    generate() {
        const body = document.querySelector('body')!;
        html2canvas(body)
            .then(canvas => {
                const ratio = canvas.width / canvas.height;
                this.doc = new jsPDF({
                    orientation: ratio > 1 ? 'l' : 'p',
                    unit: 'px',
                    format: 'a4'
                });
                const docWidth = this.doc.internal.pageSize.getWidth();
                const docHeight = ratio > 0 ? docWidth / ratio : docWidth * ratio;

                this.doc.addImage(canvas, 'PNG', 0, 0, docWidth, docHeight);
                this.doc.save('body.pdf');
            });
    }
}

export default new HTMLToPDF();