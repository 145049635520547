import React from 'react';
import './index.css';

import { formatCost} from '../../utils';
import { ISystem, IView, ViewType } from '../../interfaces';
import MetricStateLabel from '../metric-state-label';
import { useSelector } from 'react-redux';
import { IAppState } from '../../reducers';
import FormattedText from '../formatted-text';
import groupBy from 'lodash/groupBy';
import { makeGetCapabilityById } from '../../utils/selectors';
import CapabilityTree, { ICapabilityTreeStructure } from '../capability-tree';

interface Props {
    system: ISystem;
    view: IView;
}

export default function SystemModal({ system, view }: Props) {
    const views = useSelector(({ capabilities }: IAppState) => capabilities.views);
    const state = useSelector((state: IAppState) => state);

    if (!system || !view) {
        return null;
    }
    
    const getCapabilityById = makeGetCapabilityById();
    
    const filteredViews = views.filter(view => view.viewType === ViewType.SYSTEM && system.systemID === view.viewSubjectID)
    const firstLevelGroup = groupBy(filteredViews, 'L1ID');

    const capabilityTreeElements = Object.keys(firstLevelGroup).map(capabilityID => {
        const capability = getCapabilityById(state, +capabilityID)!
        const secondLevelGroup = groupBy(firstLevelGroup[capabilityID], 'capID');
        const childrenCapability = Object.keys(secondLevelGroup).map(capabilityID => getCapabilityById(state, +capabilityID));
        const capabilityTree = {
            capability,
            childrenTree: childrenCapability.map(capability => ({ capability }))
        } as ICapabilityTreeStructure;
        return <CapabilityTree capabilityTree={capabilityTree} key={capabilityID}/>
    })

    return (
        <div id='system-modal'>
            <div className='overview-container'>
                <h2>{system.name}</h2> 
                <div className='state-info'>
                    <div className='system-name'>{system.name} ({system.fullName})</div>
                    <div className='state-name'>
                        <MetricStateLabel view={view} showActualValue/>
                    </div>
                </div>
                <div className='definition-container'>
                    <h3>Definition</h3>
                    <div className='content'>
                        <FormattedText str={system.description}/>
                    </div>
                </div>
                <div className='cost-container'>
                    <h3>Cost</h3>
                    <div className='content'>£{formatCost(system.totalCostOwnership)}</div>
                </div>
            </div>
            <div className='modal-section-container'>
                <h2>Capabilities</h2>
                <div className='sub-title'>{system.name} is used in following capabilities.</div>

                { capabilityTreeElements }
            </div>
        </div>
    );
}

