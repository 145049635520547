import { GROUPS } from '../constants/action-types'
import { IActionGetAllGroups } from '../actions/groups';
import { ActionStatus } from '../constants/action-statuses';
import { DataStore } from 'services/dataStoreInterfaces';


export interface IGroupsState {
	list: DataStore.UserGroupStoreData[]
}

export const defaultState: IGroupsState = {
    list: []
};

export default (state: IGroupsState = defaultState, action: IActionGetAllGroups): IGroupsState => {
	switch (action.type) {
        case GROUPS.GET_ALL_GROUPS:
			switch(action.payload.status) {
				case ActionStatus.SUCCESS:
					return {...state, list: action.payload.response};
			}
			return state;
		default:
			return state;
	}
};