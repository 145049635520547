import { FILTERS } from "../constants/filters";
import { FilterLabels } from "../utils/filterUtils";
import { APP } from "../constants/action-types";
import { IFilter, IState, ILocation, IOrganization, ICustomerSegment, IProduct, IColorItem, IView, ViewType, MetricType } from "../interfaces";
import { IFilterActions, IViewMetricItem } from "../actions/filters";
import { IActionAppResetFile } from "../actions/auth";
import { IActionReceivedViews } from "../actions/capabilities";
import { CAPABILITIES } from "../constants/capabilities";

const UNSELECTED_FILTER_SELECTION_ID = -1;

export interface IFiltersState {
    viewMetricsMapping: Record<string, IViewMetricItem[]>
    colors: Record<string, IColorItem>
    states: IState[]
    locations: ILocation[]
    organizations: IOrganization[]
    customerSegments: ICustomerSegment[],
    products: IProduct[],
    list: IFilter[],
    views: IView[],
    defaultViewMetricFilterState: {
        view: ViewType;
        metric: MetricType;
    }
}

export const defaultState: IFiltersState = {
    viewMetricsMapping: {},
    colors: {},
    states: [],
    locations: [],
    organizations: [],
    customerSegments: [],
    products: [],
    list: [{
        label: FilterLabels.VIEW,
        selections: [],
        selectionId: 0,
    }, {
        label: FilterLabels.METRIC,
        selections: [],
        selectionId: UNSELECTED_FILTER_SELECTION_ID,
    }, {
        label: FilterLabels.STATE,
        selections: [],
        selectionId: 0,
    }, {
        label: FilterLabels.LOCATION,
        selections: [],
        selectionId: 0,
    }, {
        label: FilterLabels.BUSINESS,
        selections: [],
        selectionId: 0,
    }, {
        label: FilterLabels.PRODUCT,
        selections: [],
        selectionId: 0,
    }, {
        label: FilterLabels.CLIENT,
        selections: [],
        selectionId: 0,
    }],
    views: [],
    defaultViewMetricFilterState: {
        view: ViewType.CAPABILITY,
        metric: MetricType.EFFICIENCY
    }
};

const getDefaultSetupByLabel = (label: FilterLabels) => defaultState.list.find(setup => setup.label === label);

const updateSelections = (filter: IFilter, selections) => {
    const result = selections
        .filter(selection => selection.type !== 'Top-level')
        .map(selection => selection.name)
    filter.unselectable && result.unshift('All');    
    return result;
}

const isFilter = (filter:IFilter, label:FilterLabels) => filter.label === label;
const isFilterView = (filter:IFilter) => isFilter(filter, FilterLabels.VIEW);
const isFilterMetric = (filter:IFilter) => isFilter(filter, FilterLabels.METRIC);

export default (state: IFiltersState = defaultState, action: IFilterActions | IActionAppResetFile | IActionReceivedViews): IFiltersState => {
    switch (action.type) {
        case CAPABILITIES.RECEIVED_VIEWS_DATA:{
            return {
                ...state,
                list: state.list.map<IFilter>(filter => {
                    if(isFilterView(filter)){
                        return {
                            ...filter,
                            selections: Array.from(new Set(action.payload.views.map(_ => _.viewType)))
                        }
                    }
                    return filter;
                }),
                views: action.payload.views
            }
        }
        case FILTERS.SELECTION_CHANGED:
            return {
                ...state,
                list: state.list.map(filter => {
                    if(filter.label === action.payload.filterId) {
                        return {
                            ...filter,
                            selectionId: filter.unselectable && action.payload.selectionId === 0 
                                ? UNSELECTED_FILTER_SELECTION_ID 
                                : action.payload.selectionId
                        }
                    }
                    return filter;
                })}
        case FILTERS.CALCULATE_METRICS_FILTER_SOURCE:
            return {
                ...state,
                list: state.list.map(filter => {
                    if (isFilterMetric(filter)) {
                        const viewFilter = state.list.find(isFilterView);
                        const currViewSelection = viewFilter!.selections[viewFilter!.selectionId];
                        return getMetricFilterState(state, currViewSelection, filter);
                    }
                    return filter;
                })
            }
        case FILTERS.SET_STATES_FILTER_SOURCE:
            return {
                ...state,
                states: action.payload.states,
                list: state.list.map(filter => {
                    if (filter.label === FilterLabels.STATE) {
                        return {
                            ...filter,
                            selections: action.payload.states
                                .filter(state => state.type !== 'Top-level')
                                .map(state => state.name)
                        }
                    }
                    return filter;
                })
            }
        case FILTERS.SET_LOCATIONS_FILTER_SOURCE:
            return {
                ...state,
                locations: action.payload.locations,
                list: state.list.map(filter => {
                    if (filter.label === FilterLabels.LOCATION) {
                        return {
                            ...filter,
                            selections: updateSelections(filter, action.payload.locations)
                        }
                    }
                    return filter;
                })
            }
        case FILTERS.SET_PRODUCT_FILTER_SOURCE:
            return {
                ...state,
                products: action.payload.products,
                list: state.list.map(filter => {
                    if (filter.label === FilterLabels.PRODUCT) {
                        return {
                            ...filter,
                            selections: updateSelections(filter, action.payload.products)
                        }
                    }
                    return filter;
                })
            }
        case FILTERS.SET_BUSINESS_FILTER_SOURCE:
            return {
                ...state,
                organizations: action.payload.busineses,
                list: state.list.map(filter => {
                    if (filter.label === FilterLabels.BUSINESS) {
                        return {
                            ...filter,
                            selections: updateSelections(filter, action.payload.busineses)
                        }
                    }
                    return filter;
                })
            }
        case FILTERS.SET_CLIENT_FILTER_SOURCE:
            return {
                ...state,
                customerSegments: action.payload.clients,
                list: state.list.map(filter => {
                    if (filter.label === FilterLabels.CLIENT) {
                        return {
                            ...filter,
                            selections: updateSelections(filter, action.payload.clients)
                        }
                    }
                    return filter;
                })
            }
        case FILTERS.RESET_ALL:
            return { 
                ...state, 
                list: getDefaultFilterStates(state, state.viewMetricsMapping)
            }
        case FILTERS.VIEW_METRICTS_MAPPING_SET:
            return {
                ...state,
                viewMetricsMapping: action.payload.mapping,
                list: getDefaultFilterStates(state, action.payload.mapping)
            }

        case FILTERS.SET_DEFAULT_FILTER_SELECTION:
            return {
                ...state,
                defaultViewMetricFilterState: {view: action.payload.view, metric: action.payload.metric}
            }
        case APP.RESET_EVERYTHING_FILE:
            return defaultState;

        case FILTERS.SET_COLORS:
            return {...state, colors: action.payload};

        default:
            return state;
    }
};

const getDefaultFilterStates = (state: IFiltersState, configurationMap: Record<string, IViewMetricItem[]>): IFilter[] => 
    state.list.map(filter => {
        if (isFilterView(filter)) {
            return getViewFilterDefaultState(filter, state);
        }
        if (isFilterMetric(filter)) {
            return getDefaultMetricFilterState(filter, configurationMap, state);
        }
        return {
            ...filter,
            selectionId: filter.unselectable
                ? UNSELECTED_FILTER_SELECTION_ID
                : getDefaultSetupByLabel(filter.label)!.selectionId
        } as IFilter
    })

const getViewFilterDefaultState = (filter: IFilter, state: IFiltersState): IFilter => {
    const selections = Array.from(new Set(state.views.map(_ => _.viewType)));
    return {
        ...filter,
        selectionId: selections.indexOf(state.defaultViewMetricFilterState.view),
        selections
    }
}

const getDefaultMetricFilterState = (filter: IFilter, configurationMap: Record<string, IViewMetricItem[]>, state:IFiltersState): IFilter => {
    let selectionId = UNSELECTED_FILTER_SELECTION_ID;

    const viewSelection = state.defaultViewMetricFilterState.view;
    const selections = Array
        .from(new Set((configurationMap[viewSelection] || [])
        .filter(item => {
            return state.views.find(view => view.viewType === viewSelection && view.metricType === item.metricType && view.metricSubType === item.metricSubType)    
        })
        .map((item: any) => item.metricType)));
    const defaultViewMetric = configurationMap[viewSelection].find(_ => _.default === 'Y');
    if(defaultViewMetric) {
        selectionId = selections.indexOf(defaultViewMetric.metricType);
    }
    return {
        ...filter,
        selections,
        selectionId
    }
}
const getMetricFilterState = (state: IFiltersState, viewSelection: string, filter: IFilter): IFilter => {
    let selectionId = -1;
    const selections = Array.from(new Set(
        (state.viewMetricsMapping[viewSelection] || [])
            .filter(item => {
                return state.views.find(view => view.viewType === viewSelection && view.metricType === item.metricType && view.metricSubType === item.metricSubType)    
            })
            .map((item) => item.metricType)
    ));
    const defaultViewMetric = state.viewMetricsMapping[viewSelection].find(_ => _.default === 'Y');
    if (defaultViewMetric) {
        selectionId = selections.indexOf(defaultViewMetric.metricType);
    }
    return {
        ...filter,
        selections,
        selectionId
    };
}

