import React, { useCallback } from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { selectScenario, overlayAllCapabilities } from '../../actions/scenarios';
import Toggler from '../toggler';
import Tooltip from 'rc-tooltip';

import './index.css';
import FormattedText from '../formatted-text';

export default function ScenarioItem({ scenario, step=undefined }) {
    const dispatch = useDispatch();
    const selectedScenario = useSelector(({ scenarios }) => scenarios.selected);
    const selectScenarioDispatch = useCallback(
        () => {
            dispatch(selectScenario(scenario, step));
        },
        [dispatch, scenario, step]
    )
    const overlayAllCapabilitiesDispatch = useCallback(
        () => dispatch(overlayAllCapabilities({scenarioId: scenario.scenarioID, state: !scenario.overlayAll})),
        [dispatch, scenario]
    )

    const name = step ? step.stepName : scenario.name;
    const description = step ? step.stepDescription : scenario.description;
    const isActive = step ? selectedScenario.step === step : (selectedScenario.scenario && scenario.scenarioID === selectedScenario.scenario.scenarioID);
    const linkClassNames = classnames('link', { active: isActive })
    const hasSubLevel = isActive && !step && scenario.steps.length;

    const hoverableLink = <span
        className={linkClassNames}
        onClick={selectScenarioDispatch}
    >
        <Tooltip
            trigger={'hover'}
            placement={'bottom'}
            mouseEnterDelay={1}
            overlay={() => {
                return <div className="scenario-item-popover">
                    <div className="label-description"><FormattedText str={description}/></div>
                </div>
            }}
        >
            <span>{name}</span>
        </Tooltip>
    </span>

    if (hasSubLevel) {
        const steps = scenario.steps.map(step => <ScenarioItem key={step.stepID} scenario={scenario} step={step} />);
        return (
            <div className='scenario-item'>
                {hoverableLink}
                <div className='sub-link-block'>{steps}</div>
                <div className='select-all-section' onClick={overlayAllCapabilitiesDispatch}>
                    <div>Overlay all capabilities with this scenario</div>
                    <div className='toggler-spacer'>
                        <Toggler active={scenario.overlayAll} />
                    </div>
                </div>
            </div>
        )
    }
    return hoverableLink;
}
