
import { APP, GROUPS, USER } from '../constants/action-types';
import { toast } from 'react-toastify';
import { dataService } from '../services';
import {IGroupsActions, getAllGroups, getAllModels} from '../actions/groups';
import { ActionStatus } from '../constants/action-statuses';
import { IAdminAction, modelsChanged } from 'actions/admin';
import { getMetricsData, subscribeUserModels } from 'services/firebase/firestore';
import { IModel } from 'reducers/models';
import { appResetEverythingFile, IActionAppReset } from 'actions/auth';

let unsubscribeUserModelsUpdates;

export default ({ dispatch }) => (next) => (action:IGroupsActions | IAdminAction | IActionAppReset) => {
	switch(action.type) {
		case GROUPS.GET_ALL_GROUPS: {	
			if(action.payload.status !== ActionStatus.STARTED) {
				break;
			}		
			dataService.getAllGroups(action.payload.response.companyId)
				.then(_ => {
					dispatch(getAllGroups(ActionStatus.SUCCESS, _))
				})
				.catch(_ => {
					toast.error(_.message);
					dispatch(getAllGroups(ActionStatus.FAILED))
				});
			break;
		}
		case GROUPS.GET_ALL_MODELS: {	
			if(action.payload.status !== ActionStatus.STARTED) {
				break;
			}		
			dataService.getAllModels(action.payload.response.companyId)
				.then(_ => {
					dispatch(getAllModels(ActionStatus.SUCCESS, _))
				})
				.catch(_ => {
					toast.error(_.message);
					dispatch(getAllModels(ActionStatus.FAILED))
				});
			break;
		}

		case USER.SUBSCRIBE_MODEL_UPDATES: {
			console.log('SUBSCRIBE_MODEL_UPDATES');
			if(unsubscribeUserModelsUpdates){
				break;
			}
			const {companyId, uid} = action.payload;
			const onUserModelsChange = async (snapshot) => {
				console.log('SUBSCRIBE_MODEL_UPDATES    NEW MODEL');
				const nextModels = [] as IModel[];
				const snapshotData = snapshot.exists && snapshot.data();
				if(snapshotData && Object.keys(snapshotData).length){
					const nextModelId = Object.keys(snapshotData)[0];
					const metrics = await getMetricsData(companyId, nextModelId);
					nextModels.push({
						id: nextModelId,
						...snapshotData[nextModelId],
						metrics
					})
					
				}
				dispatch(modelsChanged(nextModels));
				dispatch(appResetEverythingFile());
			}
			unsubscribeUserModelsUpdates = subscribeUserModels(companyId, uid, onUserModelsChange)
			break;
		}
		case APP.RESET_EVERYTHING:
			unsubscribeUserModelsUpdates && unsubscribeUserModelsUpdates();
			unsubscribeUserModelsUpdates = undefined;
			break;
		default:
			break;
	}
	return next(action);
};
