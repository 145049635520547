import { APP, AUTH, SIGN_IN } from '../constants/action-types'
import { IAction } from '../reducers'
import { ActionStatus } from '../constants/action-statuses'

export interface ISignUpInfoPayload extends ISignInInfoPayload {
    companyName: string,
    userName: string,
}
export interface ISignInInfoPayload {
    email: string,
    password: string
}

export interface IFirebaseAuthResponse {
    status: ActionStatus,
    response: any
}

export interface IActionAppInit extends IAction<typeof APP.INIT> { }
export interface IActionAppReset extends IAction<typeof APP.RESET_EVERYTHING> { }
export interface IActionAppResetFile extends IAction<typeof APP.RESET_EVERYTHING_FILE> { }
export interface IActionCheckUserAuth extends IAction<typeof AUTH.CHECK_USER_AUTH> { }
export interface IActionRequestUserAuth extends IAction<typeof AUTH.REQUEST_USER_AUTH, IFirebaseAuthResponse> { }
export interface IActionSignInRequest extends IAction<typeof AUTH.SIGN_IN_REQUEST, IFirebaseAuthResponse> { }
export interface IActionSignUpRequest extends IAction<typeof AUTH.SIGN_UP_REQUEST, IFirebaseAuthResponse> { }
export interface IActionSignOutRequest extends IAction<typeof AUTH.SIGN_OUT_REQUEST, IFirebaseAuthResponse> { }
export interface IActionSignIn extends IAction<typeof SIGN_IN.SIGN_IN_REQUEST, ISignInInfoPayload> { }
export interface IActionSignUp extends IAction<typeof SIGN_IN.SIGN_UP_REQUEST, ISignUpInfoPayload> { }
export interface IActionSignOut extends IAction<typeof AUTH.SIGN_OUT> { }

export const appInit = (): IActionAppInit => ({
    type: APP.INIT,
    payload: {}
})

export const appResetEverything = (): IActionAppReset => ({
    type: APP.RESET_EVERYTHING,
    payload: {}
})

export const appResetEverythingFile = (): IActionAppResetFile => ({
    type: APP.RESET_EVERYTHING_FILE,
    payload: {}
})

export const checkUserAuth = (): IActionCheckUserAuth => ({
    type: AUTH.CHECK_USER_AUTH,
    payload: {}
})

export const requestUserAuthCheck = (status: ActionStatus, response: any): IActionRequestUserAuth => ({
    type: AUTH.REQUEST_USER_AUTH,
    payload: { status, response }
})

export const requestSignIn = (status: ActionStatus, response: any): IActionSignInRequest => ({
    type: AUTH.SIGN_IN_REQUEST,
    payload: { status, response }
})

export const requestSignUp = (status: ActionStatus, response: any): IActionSignUpRequest => ({
    type: AUTH.SIGN_UP_REQUEST,
    payload: { status, response }
})

export const requestSignOut = (status: ActionStatus, response: any): IActionSignOutRequest => ({
    type: AUTH.SIGN_OUT_REQUEST,
    payload: { status, response }
})

export const onSignIn = (email: string, password: string): IActionSignIn => ({
    type: SIGN_IN.SIGN_IN_REQUEST,
    payload: { email, password }
})

export const onSignUp = (signUpInfo: ISignUpInfoPayload): IActionSignUp => ({
    type: SIGN_IN.SIGN_UP_REQUEST,
    payload: signUpInfo
})

export const onSignOut = (): IActionSignOut => ({
    type: AUTH.SIGN_OUT,
    payload: {}
})

export type IAuthAction = IActionAppInit |
    IActionAppReset |
    IActionAppResetFile |
    IActionCheckUserAuth |
    IActionRequestUserAuth |
    IActionSignInRequest |
    IActionSignUpRequest |
    IActionSignOutRequest |
    IActionSignIn |
    IActionSignUp |
    IActionSignOut;