import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import './index.css';
import AppHeader from 'components/app-header';
import ScenariosPanel from 'components/scenarios-panel';
import TopFiltersPanel from 'components/top-filters-panel';
import CapabilitiesDashboard from 'components/dashboard';
import ActorsPane from 'components/actors-panel';
import logo from 'assets/logo-main.svg';
import { IAppState } from 'reducers';
import { subscribeModelUpdates } from 'actions/admin';


export default function DashboardPage() {
    const dispatch = useDispatch();
    const capabilities = useSelector(({ capabilities }: IAppState) => capabilities.capabilities);
    const user = useSelector(({user}:IAppState) => user);
    const [currentModel] = useState(user.models[0])
    
    useEffect(() => {
        dispatch(subscribeModelUpdates({uid: user.uid, companyId: user.companyId}))
    }, [dispatch, user.companyId, user.uid])

    if (!capabilities.length || !user.models.length || currentModel.id !== user.models[0].id) {
        return <Redirect to={'/upload'}/>
    }
    return (
        <div id='dashboard-page-container'>
            <div className='top-view-container'>
                <div className='logo-background'>
                    <div className='company-logo'>
                        <img src={logo} alt='' width='55%'/>
                    </div>
                </div>
                <div className='header-filters-container'>
                    <AppHeader darkMode/>
                    <TopFiltersPanel />
                </div>
            </div>
            <div className='main-view-container'>
                <ScenariosPanel />
                <ActorsPane />
                <CapabilitiesDashboard />
            </div>
        </div>
    )
}
