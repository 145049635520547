import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import Loader from 'react-loader-spinner';
import { receivedCapabilities, receivedActors, receivedViews, receivedViewTypeData, updateViewMetrics } from 'actions/capabilities';

import {
    setViewMetricsMapping,
    calculateMetricsFilterSource,
    setLocationsFilterSource,
    setStatesFilterSource,
    setProductFilterSource,
    setBusinessFilterSource,
    setClientFilterSource,
    setColors,
    setDefaultFilterSelection,
} from 'actions/filters';
import { receivedScenarios } from 'actions/scenarios';
import logo from 'assets/logo-main.svg';

import FileParser from 'readers/fileParser';

import './fileUpload.css';
import { appResetEverythingFile } from 'actions/auth';
import { updateModelInfo } from 'actions/model';
import { getModelData } from 'services/firebase/firestore';
import MetricsParser from 'readers/metricsParser';
import { getDownloadURLFor } from 'services/firebase/storage';
import { getUser } from 'utils/selectors';
import AppHeader from 'components/app-header';
import { showErrorToast } from 'components/toast-with-icon';

const fileLoad = (url: string): Promise<FileReader> =>
    new Promise(async (resolve, reject) => {
        const response = await fetch(url);
        if (response.ok) {
            const file = await response.blob();
            const reader = new FileReader();
            reader.onload = () => resolve(reader);
            reader.readAsBinaryString(file);
            return;
        }
        reject(`Downloding file from ${url} failed`);
    });

export default function FileUpload() {
    const [fileParsed, setFileParsed] = useState(false);
    const [fileParseError, setFileParseError] = useState<string>();
    const dispatch = useDispatch();
    const user = useSelector(getUser);

    const parseFile = useCallback(
        file => {
            const {
                capabilities,
                actors,
                viewMetricMapping,
                locations,
                states,
                views,
                products,
                organisations,
                customerSegments,
                scenarios,
                modelData,
                colors,
                viewTypesStructures,
            } = FileParser(file);

            dispatch(
                setDefaultFilterSelection({
                    view: modelData.initialView,
                    metric: modelData.initialMetric,
                }),
            );
            dispatch(receivedCapabilities(capabilities));
            dispatch(receivedActors(actors));
            dispatch(receivedViews(views));
            dispatch(receivedScenarios(scenarios));
            dispatch(receivedViewTypeData(viewTypesStructures));
            dispatch(setViewMetricsMapping(viewMetricMapping));
            dispatch(setLocationsFilterSource(locations));
            dispatch(setStatesFilterSource(states));
            dispatch(setProductFilterSource(products));
            dispatch(setBusinessFilterSource(organisations));
            dispatch(setClientFilterSource(customerSegments));
            dispatch(calculateMetricsFilterSource());

            dispatch(
                updateModelInfo({
                    name: modelData.modelName,
                    supportEmail: modelData.supportEmail,
                }),
            );
            dispatch(setColors(colors));
        },
        [dispatch],
    );

    useEffect(() => {
        dispatch(appResetEverythingFile());
        (async () => {
            if(user.models.length){
                setFileParseError(undefined);
                const model = user.models[0];
                const modelData = await getModelData(user.companyId, model.id);
                const modelDownloadUrl = await getDownloadURLFor(modelData.path);
                try {
                    const modelReader = await fileLoad(modelDownloadUrl);
                    parseFile(modelReader.result);

                    if (model.metrics && model.metrics.length && model.metrics[0].path) {
                        const metricsDownloadUrl = await getDownloadURLFor(model.metrics![0].path);
                        const metricsReader = await fileLoad(metricsDownloadUrl);
                        const { viewMetrics } = MetricsParser(metricsReader.result);
                        dispatch(updateViewMetrics(viewMetrics));
                    }
                    setFileParsed(true);
                } catch (err) {
                    const message =
                        'Something went wrong while file parsing. Please contact Support.';
                    setFileParseError(message);
                    showErrorToast(message);
                    console.warn(err);
                }
            }

        })()
    }, [dispatch, user.models, parseFile, user.companyId, user.uid])

    if (fileParsed) {
        return <Redirect to={'/dashboard'} />;
    }

    return (
        <div id='dashboard-page-container'>
            <div className='top-view-container'>
                <div className='logo-background'>
                    <div className='company-logo'>
                        <img src={logo} alt='' width='55%'/>
                    </div>
                </div>
                <div className='header-filters-container'>
                    <AppHeader darkMode/>
                </div>
            </div>
            <div className='main-view-container' style={{
                justifyContent:'center',
                alignItems:'center'
            }}>
                {user.models.length 
                    ? (<FileLoadStatus isFailed={Boolean(fileParseError)}/>)
                    : (<div>We could not find any model assigned to you...</div>)
                }
            </div>
        </div>
        
    );
}


function FileLoadStatus({isFailed=false}) {
    if(isFailed) {
        return (<div>We are sorry. We can't load your model at the moment. Please contact Support.</div>)
    }
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column'
        }}>
            <Loader
                type="TailSpin"
                color="#23408f"
                height={100}
                width={100}
            />
            <div style={{
                paddingTop: '24px'
            }}>Upload in progress</div>
        </div>
    );
}
