import React, { useState, useCallback, useEffect } from 'react';
import './index.css';
import Button from '../button';
import LabeledInput from '../labeled-input';
import DatePicker from 'react-datepicker';
import DropDown from 'components/drop-down';
import { useDispatch, useSelector } from 'react-redux';
import { addLicence, getCompaniesList } from '../../actions/admin';
import { Multiselect } from 'multiselect-react-dropdown';

import "react-datepicker/dist/react-datepicker.css";
import { dataService } from '../../services';
import { IUser } from '../users-list';
import { IAppState } from 'reducers';
import { getCompaniesListData, getCompaniesNames } from 'utils/selectors';
import { User } from 'services/dataStoreInterfaces';
import { hide } from 'actions/modals';

export default function AddLicenceModal() {
    const state = useSelector((state:IAppState) => state)
    const [selectedCompanyId, setSelectedCompanyId] = useState(0);
    const [renewAt, setRenewAt] = useState(new Date());
    const [licenceName, setLicenceName] = useState('');
    const [addedUsers, setAddedUsers] = useState<IUser[]>([]);
    const [allUsers, setAllUsers] = useState<IUser[]>([]);

    const companies = getCompaniesListData(state);
    const companiesNames = getCompaniesNames(state);

    const dispatch = useDispatch();
    const onButtonClick = useCallback(() => {
        dispatch(addLicence({
            companyId: companies[selectedCompanyId].id,
            name: licenceName, 
            users: addedUsers.map(({uid}) => uid),
            renewAt,
        }))
        dispatch(hide());
    }, [dispatch, companies, selectedCompanyId, licenceName, addedUsers, renewAt]);
    const onAddedUsersUpdated = useCallback(
        (selectedList) => setAddedUsers(selectedList),
        []
    )
    const onUserCompanyUpdated = useCallback(
        (id) => {
            setSelectedCompanyId(id);
        },
        [],
    )

    useEffect(() => {
        (async () => {
            const email: User.email = 'emails';
            const companyId = companies[selectedCompanyId].id;
            const allUsers = await dataService.getAllUsers(companyId);
            const result = await Promise.all(allUsers.map(async user => {
                const groups = await dataService.getUserGroups(email, user.uid);
                const models = await dataService.getUserModels(companyId, user.uid);
                return {...user, groups, models};
            }))
            setAllUsers(result);
        })();
    }, [companies, selectedCompanyId])

    useEffect(() => {
            dispatch(getCompaniesList({}));
    }, [dispatch]);

    return (
        <div id="add-licence-modal">
            <h2 className="add-licence-modal_title">Add Licence</h2>
            <div className="add-licence-modal_selectors-section">
                <LabeledInput
                    label="NAME"
                    placeholder="Type here"
                    onChange={event => {
                        setLicenceName(event.currentTarget.value);
                    }}
                />

                <div className="add-licence-modal_field-title">COMPANY</div>
                    <DropDown
                        selectionId={selectedCompanyId}
                        selections={companiesNames}
                        label={companiesNames[selectedCompanyId]}
                        onSelectionChanged={onUserCompanyUpdated}
                    />

                <div className="add-licence-modal_field-title">ASSIGN USER</div>
                <div className="add-licence-modal_users">
                    <Multiselect
                        options={allUsers}
                        onSelect={onAddedUsersUpdated}
                        onRemove={onAddedUsersUpdated}
                        displayValue="email"
                        showCheckbox
                        closeOnSelect={false}
                        avoidHighlightFirstOption
                        placeholder="Assign people"
                    />
                </div>
                <div className="add-licence-modal_field-title">RENEWAL DATE</div>
                <div className="add-license-modal_date">
                    <DatePicker selected={renewAt} onChange={setRenewAt} />
                </div>
            </div>
            <Button onClick={onButtonClick} primary>
                Done
            </Button>
        </div>
    );
}

