import React, { useEffect, useState } from 'react';

import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { onSignOut } from 'actions/auth';
import AppIcon from 'components/app-icon';
import { IAppState } from 'reducers';
import { BaseDropDown } from '../base-drop-down';
import { showContactSupportModal } from 'actions/modals';
import { Link } from 'react-router-dom';
import { getUserAdminStatus } from 'utils/selectors';
import FileParser from 'readers/fileParser';
import { toast } from 'react-toastify';
import { getModelData } from 'services/firebase/firestore';
import { getDownloadURLFor } from 'services/firebase/storage';
import { updateModelInfo } from 'actions/model';

interface Props {
    darkMode?: boolean;
}

interface IMenuItem {
    id: MenuItemKey;
    label: string;
    url?: string;
}

enum MenuItemKey {
    DASHBOARD,
    ADMIN_PANEL,
    HELP_PAGE,
    CONTACT_SUPPORT,
    LOGOUT,
}

const fileLoad = (url: string): Promise<FileReader> =>
    new Promise(async (resolve, reject) => {
        const response = await fetch(url);
        if (response.ok) {
            const file = await response.blob();
            const reader = new FileReader();
            reader.onload = () => resolve(reader);
            reader.readAsBinaryString(file);
            return;
        }
        reject(`Downloding file from ${url} failed`);
    });

export default function AppHeader({ darkMode }: Props) {
    const [dropDownState, setDropDownState] = useState(false);
    const dispatch = useDispatch();
    const state = useSelector((state: IAppState) => state);
    const hasUserAdminRole = getUserAdminStatus(state);
    const user = useSelector(({ user }: IAppState) => user);
    const name = useSelector(({ user }: IAppState) => user.name);
    const modelName = useSelector(({ model }: IAppState) => model.name);
    const headerClassNames = classnames({ dark: darkMode });

    const menuItems: IMenuItem[] = [
        {
            id: MenuItemKey.DASHBOARD,
            label: 'Dashboard',
            url: '/dashboard',
        },
        {
            id: MenuItemKey.CONTACT_SUPPORT,
            label: 'Contact Support',
        },
        // {
        //     id: MenuItemKey.HELP_PAGE,
        //     label: 'Help page',
        //     url: '/help',
        // },
        {
            id: MenuItemKey.LOGOUT,
            label: 'Log out',
        },
    ];
    if (hasUserAdminRole) {
        menuItems.splice(1, 0, {
            id: MenuItemKey.ADMIN_PANEL,
            label: 'Admin panel',
            url: '/admin',
        });
    }
    const onMoreMenuItemClicked = (id: MenuItemKey) => {
        switch (id) {
            case MenuItemKey.LOGOUT:
                dispatch(onSignOut());
                break;
            case MenuItemKey.CONTACT_SUPPORT:
                dispatch(showContactSupportModal());
                break;
            default:
        }
    };

    useEffect(() => {
        if (!modelName) {
            (async () => {
                if (user.models.length) {
                    const model = user.models[0];
                    const modelData = await getModelData(user.companyId, model.id);
                    const modelDownloadUrl = await getDownloadURLFor(modelData.path);
                    try {
                        const modelReader = await fileLoad(modelDownloadUrl);
                        const { modelData } = FileParser(modelReader.result);
                        dispatch(
                            updateModelInfo({
                                name: modelData.modelName,
                                supportEmail: modelData.supportEmail,
                            }),
                        );
                    } catch (err) {
                        toast.error(err);
                    }
                }
            })();
        }
    }, [dispatch, modelName, user.companyId, user.models, user.uid]);

    return (
        <div id="app-header" className={headerClassNames}>
            <div className="user-section">
                {modelName && (
                    <div className="header-block company">
                        <AppIcon iconName="company" />
                        <span className="company-name">{modelName}</span>
                    </div>
                )}
                <div className="separator"></div>
                <BaseDropDown
                    renderSelections={() =>
                        menuItems.map(selection => {
                            const listItem = (
                                <div
                                    className="list-item"
                                    key={selection.id}
                                    data-id={selection.id}
                                >
                                    {selection.label}
                                </div>
                            );
                            return selection.url ? (
                                <Link to={selection.url} key={selection.id}>
                                    {listItem}
                                </Link>
                            ) : (
                                listItem
                            );
                        })
                    }
                    onSelectionChanged={onMoreMenuItemClicked}
                    onStateChanged={setDropDownState}
                >
                    <div className="header-block user">
                        <AppIcon iconName={'user'} />
                        <span className="username-label">{name}</span>
                        <AppIcon
                            iconName={'arrow_down'}
                            className={classnames('show-arrow_icon', { expanded: dropDownState })}
                        />
                    </div>
                </BaseDropDown>
            </div>
        </div>
    );
}
