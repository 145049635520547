import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers/index'
import { composeWithDevTools } from 'redux-devtools-extension';
import auth from './middlewares/auth';
import admin from './middlewares/admin';
import data from './middlewares/data';

export const history = createBrowserHistory()

export default function configureStore(preloadedState: any) { // TODO: any
    // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const composeEnhancers = composeWithDevTools({
        // Specify name here, actionsBlacklist, actionsCreators and other options if needed
    });
    const store = createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
        composeEnhancers(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                // ... other middlewares ...
                auth,
                admin,
                data
            ),
        ),
    )

    return store;
}