import React, { useState, useEffect, useCallback } from 'react';
import AppIcon from '../app-icon/index';
import classnames from 'classnames';

import './index.css';

interface Props {
    selectionId: number,
    selections: string[];
    label: string,
    onSelectionChanged: Function
}

export default function Dropdown({ selectionId , selections , label , onSelectionChanged  }: Props) {
    const [expanded, setExpanded] = useState(false);
    const dropdownClassNames = classnames('drop-down-container', { expanded })

    const hideSelections = useCallback(
        () => {
            setExpanded(false)
            document.removeEventListener('click', hideSelections);
        },
        []
    )

    const onToggle = () => {
        if (!expanded) {
            document.addEventListener('click', hideSelections);
        }
        setExpanded(!expanded);
    }

    const onSelectionClick = (event) => {
        onToggle();
        const newSelectionId = parseInt(event.target.dataset.id);
        if (!isNaN(newSelectionId) && newSelectionId !== selectionId) {
            onSelectionChanged(newSelectionId);
        }
    }

    useEffect(() => {
        return () => {
            document.removeEventListener('click', hideSelections);
        }
    }, [hideSelections])

    return (
        <div onClick={onToggle} className={dropdownClassNames}>
            <div className='label-container'>
                <div className='label'>{label}</div>
                <div className='icon'><AppIcon iconName={expanded ? 'collapse' : 'expand'} /></div>
            </div>
            {expanded &&
                <div onClick={onSelectionClick} className='list-container'>
                    {selections.map((selection, key) => (
                        <div className='list-item' key={key} data-id={key}>{selection}</div>
                    ))}
                </div>}
        </div>
    )
}