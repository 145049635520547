import XLSX from 'xlsx';
import mem from 'mem';
import { IViewMetric } from 'interfaces';

const convertSheetToObjectsArray = XLSX.utils.sheet_to_json;

export default (bstr: any) => {
    const getSheetByName = mem((name: string) => workbook.Sheets[name]);

    const workbook = XLSX.read(bstr, { type: "binary" });

    const viewMetrics = convertSheetToObjectsArray<IViewMetric>(
      getSheetByName("metrics"),
      { range: 2 } // header row is at 3 row
    );

    return { viewMetrics };
};
