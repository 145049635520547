import React, { useCallback, useState } from 'react';
import { Tabs, useTabState, Panel } from '@bumaga/tabs';

import './index.css';
import AppHeader from '../../components/app-header';
import logo from '../../assets/logo-main.svg';
import { useSelector, useDispatch } from 'react-redux';
import { showInviteUserModal, showAddLicenceModal, showAddCompanyModal } from 'actions/modals';
import Button from 'components/button';
import cx from 'classnames';
import UsersList from 'components/users-list';
import LicencesList from 'components/licences-list';
// import { isUserInAdminGroup as isUserInAdminGroupSelector } from 'utils/selectors';
import { getUserAdminRoleStatus as getUserAdminRoleStatusSelector } from 'utils/selectors';
import CompaniesList from 'components/companies-list';

const Tab = ({ children }) => {
    const { isActive, onClick } = useTabState();

    return (
        <div className={cx('admin-page_tab', { active: isActive })} onClick={onClick}>
            {children}
        </div>
    );
};

export default function AdminPage() {
    const [index, setIndex] = useState(0);
    const dispatch = useDispatch();
    // const isUserInAdminGroup = useSelector(isUserInAdminGroupSelector);
    const isUserSuperAdmin = useSelector(getUserAdminRoleStatusSelector);
    const onInviteUserClick = useCallback(() => {
        dispatch(showInviteUserModal());
    }, [dispatch]);
    const onAddLincenceClick = useCallback(() => {
        dispatch(showAddLicenceModal());
    }, [dispatch]);
    const onAddCompanyClick = useCallback(() => {
        dispatch(showAddCompanyModal());
    }, [dispatch]);

    const renderInviteButton = () => {
        if (isUserSuperAdmin) {
            switch (index) {
                case 0: // companies
                    return (
                        <Button onClick={onAddCompanyClick} primary>
                            Add company
                        </Button>
                    );
                case 1: // users
                    return (
                        <Button onClick={onInviteUserClick} primary>
                            Invite user
                        </Button>
                    );
                case 2: // licences
                    return (
                        <Button onClick={onAddLincenceClick} primary>
                            Add licence
                        </Button>
                    );
                default:
                    return null;
            }
        }
        return (
            <Button onClick={onInviteUserClick} primary>
                Invite user
            </Button>
        );
    };
    return (
        <div id="admin-page">
            <div className="top-view-container">
                <div className="logo-background">
                    <div className="company-logo">
                        <img src={logo} alt="" width="55%" />
                    </div>
                </div>
                <div className="header-filters-container">
                    <AppHeader darkMode />
                </div>
            </div>
            <div className="admin-page_main">
                <div className="admin-page_title">
                    <span>Admin panel</span>
                    {renderInviteButton()}
                </div>
                <Tabs state={[index, setIndex]}>
                    <div className="admin-page_tabs">
                        {isUserSuperAdmin && (
                            <Tab>
                                <div>Companies</div>
                            </Tab>
                        )}
                        <Tab>
                            <div>Users</div>
                        </Tab>
                        {isUserSuperAdmin && (
                            <Tab>
                                <div>Licences</div>
                            </Tab>
                        )}
                    </div>
                    {isUserSuperAdmin && (
                        <Panel>
                            <CompaniesList />
                        </Panel>
                    )}
                    <Panel>
                        <UsersList />
                    </Panel>
                    {isUserSuperAdmin && (
                        <Panel>
                            <LicencesList />
                        </Panel>
                    )}
                </Tabs>
            </div>
        </div>
    );
}
