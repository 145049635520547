import { ModalTypes, ModalActions } from "../constants/modals";
import { IModalActions } from "../actions/modals";

export interface IModalsState {
    isOpen: boolean,
    type?: ModalTypes,
    modalData: any
}

export const defaultState: IModalsState = {
    isOpen: false,
    type: undefined,
    modalData: {}
};

export default (state: IModalsState = defaultState, action: IModalActions): IModalsState => {
    switch (action.type) {
        case ModalActions.SHOW_SYSTEM:
            return {
                ...defaultState,
                isOpen: true,
                type: ModalTypes.SYSTEM,
                modalData: {
                    system: action.payload.system,
                    view: action.payload.view
                }
            };
        case ModalActions.SHOW_CAPABILITY:
            return {
                ...defaultState,
                isOpen: true,
                type: ModalTypes.CAPABILITY,
                modalData: {
                    capability: action.payload.capability
                }
            };
        case ModalActions.SHOW_ACTOR:
            return {
                ...defaultState,
                isOpen: true,
                type: ModalTypes.ACTOR,
                modalData: {
                    actor: action.payload.actor
                }
            };
        case ModalActions.SHOW_GENERAL:
            return {
                ...defaultState,
                isOpen: true,
                type: ModalTypes.GENERAL,
                modalData: {
                    data: action.payload.data,
                    view: action.payload.view
                }
            };
        case ModalActions.SHOW_INVITE_USER:
            return {
                ...defaultState,
                isOpen: true,
                type: ModalTypes.INVITE_USER,
                modalData: {}
            };
        case ModalActions.SHOW_ADD_LICENCE:
            return {
                ...defaultState,
                isOpen: true,
                type: ModalTypes.ADD_LICENCE,
                modalData: {}
            };
        case ModalActions.SHOW_ADD_COMPANY:
            return {
                ...defaultState,
                isOpen: true,
                type: ModalTypes.ADD_COMPANY,
                modalData: {}
            };
        case ModalActions.SHOW_UPLOAD_MODAL:
            return {
                ...defaultState,
                isOpen: true,
                type: ModalTypes.UPLOAD_MODAL,
                modalData: {uid: action.payload.uid}
            };
        case ModalActions.SHOW_EDIT_USER_MODAL:
            return {
                ...defaultState,
                isOpen: true,
                type: ModalTypes.EDIT_USER_MODAL,
                modalData: {uid: action.payload.uid}
            };
        case ModalActions.SHOW_EDIT_LICENSE_MODAL:
            return {
                ...defaultState,
                isOpen: true,
                type: ModalTypes.EDIT_LICENSE_MODAL,
                modalData: {uid: action.payload.uid, companyId: action.payload.companyId}
            };
        case ModalActions.SHOW_CONTACT_SUPPORT:
            return {
                ...defaultState,
                isOpen: true,
                type: ModalTypes.CONTACT_SUPPORT,
                modalData: {}
            };
        case ModalActions.SHOW_METRICS_UPLOAD:
            return {
                ...defaultState,
                isOpen: true,
                type: ModalTypes.METRICS_UPLOAD,
                modalData: {
                    uid: action.payload.uid,
                    companyId: action.payload.companyId,
                    modelId:action.payload.modelId
                }
            };
        case ModalActions.HIDE: 
            return defaultState;
        default:
            return state;
    }
};