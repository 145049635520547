import {requestSignIn, requestUserAuthCheck, checkUserAuth, appResetEverything, onSignOut} from '../actions/auth'
import { APP, AUTH, SIGN_IN } from '../constants/action-types';
import { ActionStatus } from '../constants/action-statuses';
import { authService } from '../services';
import { subscribeModelUpdates } from 'actions/admin';
import { CURRENT_FILE_STORAGE_KEY } from '../constants/file-content';
import { showErrorToast } from 'components/toast-with-icon'

export default ({ dispatch }) => (next) => (action) => {
	switch(action.type) {
		case APP.INIT: {
			dispatch(checkUserAuth())
			break;
		}

		case AUTH.CHECK_USER_AUTH: {
			dispatch(requestUserAuthCheck(ActionStatus.STARTED));
			authService
				.checkAuth()
				.then(_ => {
					dispatch(subscribeModelUpdates({uid: _.uid, companyId: _.companyId}))
					dispatch(requestUserAuthCheck(ActionStatus.SUCCESS, _))
				})
				.catch(() => {
					dispatch(onSignOut(ActionStatus.STARTED));
					dispatch(requestUserAuthCheck(ActionStatus.FAILED));
				})
			break;
		}
		case SIGN_IN.SIGN_IN_REQUEST: {
			dispatch(requestSignIn(ActionStatus.STARTED));
			authService
				.signIn(action.payload.email, action.payload.password)
				.then(_ => {
					dispatch(subscribeModelUpdates({uid: _.uid, companyId: _.companyId}))
					dispatch(requestSignIn(ActionStatus.SUCCESS, _))
				})
				.catch(_ => {
					showErrorToast(_.message);
					dispatch(requestSignIn(ActionStatus.FAILED, _))
				});
			break;
		}
		case SIGN_IN.SIGN_UP_REQUEST: {
			dispatch(requestSignIn(ActionStatus.STARTED));
			const {email, userName, companyName} = action.payload;
			authService
				.signUp(action.payload.email, action.payload.password, {email, userName, companyName})
				.then(_ => dispatch(requestSignIn(ActionStatus.SUCCESS, _)))
				.catch(_ => {
					showErrorToast(_.message);
					dispatch(requestSignIn(ActionStatus.FAILED, _))
				});
			break;
		}
		case AUTH.SIGN_OUT: {			
			authService.signOut()
				.then(_ => {
					localStorage.removeItem(CURRENT_FILE_STORAGE_KEY);
					dispatch(appResetEverything());
				})
				.catch(_ => showErrorToast(_.message));
			break;
		}
		default:
			break;
	}
	return next(action);
};
