import React from 'react';
import AppHeader from 'components/app-header';
import './index.css';

import logo from 'assets/logo-orange.svg';
import LabeledInput from 'components/labeled-input';
import AppIcon from 'components/app-icon';

export default function HelpPage() {
    return (
        <div id="help-page">
            <div className="help-page-header">
                <div className="logo-background">
                    <div className="company-logo">
                        <img src={logo} alt="" width="70" />
                    </div>
                </div>

                <AppHeader />
            </div>
            <div className="help-page-container">
                <div className="filter-wrapper">
                    <h2>How we can help you?</h2>
                    <LabeledInput
                        name="search"
                        type="text"
                        iconName="search"
                        label=""
                        placeholder="Search"
                        onChange={() => {}}
                    />
                </div>
                <div className="help-page-sections">
                    <div className="help-page-section">
                        <AppIcon iconName="play" />
                        <h2>Getting started</h2>
                        <p>
                            New around here? Our guide will teach you everything you need to know!
                        </p>
                    </div>
                    <div className="help-page-section">
                        <AppIcon iconName="settings" />
                        <h2>Getting started</h2>
                        <p>Customize your account according to your preferences.</p>
                    </div>
                    <div className="help-page-section">
                        <AppIcon iconName="faq" />
                        <h2>Getting started</h2>
                        <p>Here you will find quick answers to common questions!</p>
                    </div>
                    <div className="help-page-section">
                        <AppIcon iconName="play" />
                        <h2>Getting started</h2>
                        <p>
                            New around here? Our guide will teach you everything you need to know!
                        </p>
                    </div>
                    <div className="help-page-section">
                        <AppIcon iconName="play" />
                        <h2>Getting started</h2>
                        <p>
                            New around here? Our guide will teach you everything you need to know!
                        </p>
                    </div>
                    <div className="help-page-section">
                        <AppIcon iconName="play" />
                        <h2>Getting started</h2>
                        <p>
                            New around here? Our guide will teach you everything you need to know!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
