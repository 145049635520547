import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { onSignIn } from '../../actions/auth';
import LabeledInput from '../../components/labeled-input'
import Button from '../../components/button'

import './signIn.css';
import { IAppState } from '../../reducers';
import { Link } from 'react-router-dom';

const errorMessages = {
    general: 'something wrong...',
    default: {
        required: 'field is required',
    },
    email: {
        pattern: 'incorrect email format'
    },
    password: {
        minLength: 'password\'s length should be in range 4-20',
        maxLength: 'password\'s length should be in range 4-20'
    }
}

type EmailInputType = 'email';
type PasswordInputType = 'password';

interface ISignForm {
    email: EmailInputType;
    password: PasswordInputType;
}

const EMAIL:EmailInputType = 'email';
const PASSWORD:PasswordInputType = 'password';

export default function SignInPage() {
    const dispatch = useDispatch();
    const isSignInInProgress = useSelector(({ auth }: IAppState) => auth.isSignInInProgress);
    const [email, changeEmail] = useState('');
    const [password, changePassword] = useState('');
    const { register, unregister, setValue, handleSubmit, errors, clearErrors} = useForm<ISignForm>();

    const onSignInHandler = () => {
        dispatch(onSignIn(email, password));
    }

    const onEmailChangeHandler = useCallback(
        (event) => {
            clearErrors(EMAIL)
            setValue(EMAIL, event.target.value, { shouldValidate: true, shouldDirty: true});
            changeEmail(event.target.value)
        }, [clearErrors, setValue])

    const onPasswordChangeHandler = useCallback( 
        (event) => {
            clearErrors(PASSWORD);
            setValue(PASSWORD, event.target.value, { shouldValidate: true, shouldDirty: true});
            changePassword(event.target.value);
        }, [clearErrors, setValue])

    useEffect(() => {
        register({ name: EMAIL, type: 'custom' }, {required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ });
        register({ name: PASSWORD, type: 'custom' }, {required: true, minLength: 4, maxLength: 20 });
        return () => {
            unregister([EMAIL, PASSWORD])
        }
    }, [register, unregister]);

    const getError = (field) => {
        if(!errors[field]) {
            return null;
        }
        if(errorMessages[field]) {
            return errorMessages[field][errors[field].type] || errorMessages.default[errors[field].type] || null;
        }
        return '';
    }

    return (
        <div id='sign-in-form-container'>
            <form onSubmit={handleSubmit(onSignInHandler)}>
                <div className='title'>SIGN IN</div>
                <LabeledInput 
                    iconName='mail'
                    label={EMAIL}
                    placeholder='Your Email'
                    onChange={onEmailChangeHandler}
                    autoComplete={EMAIL}
                    value={email}
                    type={EMAIL}
                    name={EMAIL}
                    error={getError(EMAIL)}
                    rounded
                />
                <LabeledInput
                    iconName='key'
                    label={PASSWORD}
                    placeholder='Your password'
                    onChange={onPasswordChangeHandler}
                    autoComplete={PASSWORD}
                    value={password}
                    type={PASSWORD}
                    name={PASSWORD}
                    error={getError(PASSWORD)}
                    rounded
                />
                <Link to={'password-reset'} style={{'textDecoration': 'none'}}>
                    <div className='forget-password-label'>Forget password?</div>
                </Link>
                <div className='bot-container'>
                    <Button primary disabled={isSignInInProgress} type="submit">Sign in</Button>
                </div>
            </form>
        </div>
    );
}
