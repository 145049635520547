import React from 'react';
import './index.css';
import FormattedText from '../formatted-text';
import { IView } from 'interfaces';

export interface IGenericModalItem {
    name: string;
    description: string;
    cost: number;
    fullName: string;
}

interface Props {
    data: IGenericModalItem
    view: IView
}

export default function GenericModal({ data, view }: Props) {

    if (!data) {
        return null;
    }

    return (
        <div id="generic-modal">
            <div className="overview-container">
                <h2 className="title">{data.name}</h2>
                <div className="definition-container">
                    <h3>Definition</h3>
                    <div className="content">
                        <FormattedText str={view.commentary || data.description} />
                    </div>
                </div>
            </div>
        </div>
    );
}

