import firebase from 'firebase/app';
import 'firebase/firestore';
import { uploadModel as storageUploadModel, uploadMetrics as storageUploadMetrics } from './storage';
import { IModel } from '../../reducers/model';
import { ICompany } from 'reducers/admin';
import { DataStore, Company, User, Licence, Model } from 'services/dataStoreInterfaces';
import { Firebase } from './interfaces';
import { adminService } from 'services';

export enum STORE_ENTITY_TYPES {
    USERS = 'users',
    MODELS = 'models',
    GROUPS = 'groups',
    LICENCES = 'licences',
    COMAPANIES = 'companies'
}
export enum DATABASE_COLLECTION_NAMES {
    FIREBASE_DATABASE_USERS_ROOT = 'users',
    FIREBASE_DATABASE_MODELS_ROOT = 'models',
    FIREBASE_DATABASE_GROUPS_ROOT = 'groups',
    FIREBASE_DATABASE_LICENCES_ROOT = 'licences',
    FIREBASE_DATABASE_COMPANIES_ROOT = 'companies',
}

const getCollectionNameByEntityType = (type?:STORE_ENTITY_TYPES):DATABASE_COLLECTION_NAMES => {
    switch(type) {
        case STORE_ENTITY_TYPES.MODELS:
            return DATABASE_COLLECTION_NAMES.FIREBASE_DATABASE_MODELS_ROOT
        case STORE_ENTITY_TYPES.GROUPS:
            return DATABASE_COLLECTION_NAMES.FIREBASE_DATABASE_GROUPS_ROOT
        case STORE_ENTITY_TYPES.LICENCES:
            return DATABASE_COLLECTION_NAMES.FIREBASE_DATABASE_LICENCES_ROOT
        case STORE_ENTITY_TYPES.COMAPANIES:
            return DATABASE_COLLECTION_NAMES.FIREBASE_DATABASE_COMPANIES_ROOT
        case STORE_ENTITY_TYPES.USERS:
        default:
            return DATABASE_COLLECTION_NAMES.FIREBASE_DATABASE_USERS_ROOT
    }
}

const getStoreCollectionRef = (type:STORE_ENTITY_TYPES) => 
    firebase.firestore()
        .collection(getCollectionNameByEntityType(type))

const getStoreCollection = (type:STORE_ENTITY_TYPES) => 
    getStoreCollectionRef(type)
        .get();

export const getCompaniesRef = () => firebase.firestore()
    .collection(STORE_ENTITY_TYPES.COMAPANIES);
export const getCompanyRef = (cid?:Company.Id) => {
    const companiesRef = getCompaniesRef();
    return cid ? companiesRef.doc(cid) : companiesRef.doc();
};

export const getUsersRef = (cid:string) => getCompanyRef(cid)
    .collection(STORE_ENTITY_TYPES.USERS);
export const getUserRef = (cid:string, uid?:string) => {
    const usersRef = getUsersRef(cid);
    return uid ? usersRef.doc(uid) : usersRef.doc();
};

export const getModelsRef = (cid:Company.Id) => getCompanyRef(cid)
    .collection(STORE_ENTITY_TYPES.MODELS);
export const getModelRef = (cid:Company.Id, id?:Model.id) => {
    const modelsRef = getModelsRef(cid);
    return id ? modelsRef.doc(id) : modelsRef.doc();
};

export const getGroupsRef = (cid:string) => getCompanyRef(cid)
    .collection(STORE_ENTITY_TYPES.GROUPS);
export const getGroupRef = (cid:string, uid?:string) => {
    const groupsRef = getGroupsRef(cid);
    return uid ? groupsRef.doc(uid) : groupsRef.doc();
};

export const getLicencesRef = (cid:Company.Id) => getCompanyRef(cid)
    .collection(STORE_ENTITY_TYPES.LICENCES);
export const getLicenceRef = (cid:Company.Id, id?:Licence.id) => {
    const licencesRef = getLicencesRef(cid);
    return id ? licencesRef.doc(id) : licencesRef.doc();
};

export const getGroups = () => getStoreCollection(STORE_ENTITY_TYPES.GROUPS);
export const getModels = () => getStoreCollection(STORE_ENTITY_TYPES.MODELS);
export const getLicences = () => getStoreCollection(STORE_ENTITY_TYPES.LICENCES);
export const getCompanies = () => getStoreCollection(STORE_ENTITY_TYPES.COMAPANIES);


export const updateUserByUID = (cid: string, uid:string, data = {}) => {
    const userRef = getUserRef(cid, uid);
    return userRef.update(data);
}

export const getLicenceData = (cid:Company.Id, id:Licence.id):Promise<DataStore.LicenceStoreDataWithId> =>
    getLicenceRef(cid, id)
        .get()
        .then(doc => {
            if(!doc.exists) {
                throw new Error(`Licence ${id} was not found in company ${cid}`);
            }
            const data = doc.data() as Firebase.Firestore.LicenceStoreDataWithId;
            if(!data) {
                throw new Error(`Licence ${id} in company ${cid} has no data`);
            }
            return {
                ...data,
                renewAt: data.renewAt.toDate(),
                createdAt: data.createdAt.toDate()
            } as DataStore.LicenceStoreDataWithId;
        });
export const getModelData = (cid:string, id:string) =>
    getModelRef(cid, id)
        .get()
        .then(doc => {
            if (doc.exists) {
                return doc.data() as Firebase.Firestore.ModelStoreData;
            } else {
                throw new Error("No such document!")
            }
        });
export const getMetricsData = (cid:string, modelId:string) =>
    getModelRef(cid, modelId)
        .collection('metrics')
        .orderBy('createdAt', 'desc')
        .get()
        .then(snapshot => snapshot.docs
            .map(doc => doc.exists && {id:doc.id, ...doc.data()})
            .filter(Boolean));
            
export const getUserDataByUID = (cid:Company.Id, uid:User.id):Promise<DataStore.UserStoreData> =>
    getUserRef(cid, uid)
        .get()
        .then(doc => {
            if (doc.exists) {
                return doc.data() as Firebase.Firestore.UserStoreData as DataStore.UserStoreData;
            } else {
                throw new Error("No such document!")
            }
        });

export const getAllUsers = (cid:string):Promise<DataStore.UserStoreDataWithId[]> =>
    getUsersRef(cid)
        .orderBy('email')
        .get()
        .then(snapshot => snapshot.docs
            .reduce((acc, doc) => {
                if(doc.exists) {
                    acc.push({ 
                        uid: doc.id, 
                        ...doc.data() as Firebase.Firestore.UserStoreData
                    } as DataStore.UserStoreDataWithId);
                }
                return acc;
            }, [] as DataStore.UserStoreDataWithId[]))

export const getAllGroups = (cid:string) => 
    getGroupsRef(cid)
        .orderBy('name')
        .get()
        .then(snapshot => snapshot.docs
            .map(_ => _.exists && {id:_.id, ..._.data()})
            .filter(_ => Boolean(_)));

export const getAllCompanies = () => 
    getCompanies()
        .then(snapshot => snapshot.docs
            .map(_ => _.exists && {id:_.id, ..._.data()})
            .filter(_ => Boolean(_)) as ICompany[]);

export const getAllModels = (cid:string) => 
    getModelsRef(cid)
        .orderBy('name')
        .get()
        .then(snapshot => snapshot.docs
            .map(_ => _.exists && {id:_.id, ..._.data()})
            .filter(_ => Boolean(_)));

type onSnapshotCallbackArgs = Record<firebase.firestore.DocumentChangeType, Record<string, Firebase.Firestore.LicenceStoreData>>
type onSnapshotCallback = (data:onSnapshotCallbackArgs) => void;


const licencesSubs = {};
const cacheLicencesSubscription = (key, callback, subscribe,  unsubscribe, clearKeyDelay?) => cacheSubscriptionBy(licencesSubs, key, callback, subscribe,  unsubscribe, clearKeyDelay);
const companiesSubs = {};
const cacheCompaniesSubscription = (key, callback, subscribe,  unsubscribe, clearKeyDelay?) => cacheSubscriptionBy(companiesSubs, key, callback, subscribe,  unsubscribe, clearKeyDelay);
const cacheSubscriptionBy = (cache, key:string, callback:(data:any)=>void, subscribe, unsubscribe:any, clearKeyDelay=2000000) => {
    if(!cache[key]) {
        subscribe(data => Object.values(cache[key].subs).forEach((sub:any) => sub(data)));
        cache[key] = {
            subs: [],
            unsub: unsubscribe
        }
    }
    clearTimeout(cache[key].unSubTimeout);
    const unsubId = key+Object.keys(cache[key].subs).length;
    cache[key].subs[unsubId] = callback;
    return () => {
        delete cache[key].subs[unsubId]
        if(!Object.keys(cache[key].subs).length){
            cache[key].unSubTimeout = setTimeout(() => {    
                cache[key].unsub();
                delete cache[key];
            }, clearKeyDelay);
        }
    };
}
const globalCache = {
    licencesSubs,
    companiesSubs
};
window.globalCache = globalCache;
const getDataFromSnapshot = (snapshot) => 
    snapshot
        .docChanges()
        .reduce((acc, change) => {
            if(!acc[change.type]){
                acc[change.type] = {}
            }
            acc[change.type][change.doc.id] = change.doc.data() as Firebase.Firestore.LicenceStoreData
            return acc;
        }, {} as onSnapshotCallbackArgs)


        export const subscribeAllCompanies = (callback:onSnapshotCallback) => {
    let unsubscribe:()=>void;
    return cacheCompaniesSubscription(
        'companies', 
        callback, 
        (subCallback) => {
            unsubscribe = getCompaniesRef()
                .onSnapshot(snapshot => subCallback(getDataFromSnapshot(snapshot)))
        }, 
        () => unsubscribe())
}
export const subscribeAllCompanyLicences = (cid:string, callback:onSnapshotCallback) => {
    let unsubscribe:()=>void;
    return cacheLicencesSubscription(
        cid, 
        callback, 
        (subCallback) => {
            unsubscribe = getLicencesRef(cid)
                .onSnapshot(snapshot => subCallback(getDataFromSnapshot(snapshot)))
        }, 
        () => unsubscribe())
}

export const getAllLicences = (cid:string):Promise<DataStore.LicenceStoreDataWithId[]> => 
    getLicencesRef(cid)
        .orderBy('companyId')
        .get()
        .then(snapshot => snapshot.docs
            .reduce((acc, doc) => {
                if(doc.exists) {
                    const data = doc.data() as Firebase.Firestore.LicenceStoreData;

                    acc.push({
                            id: doc.id,
                            ...data,
                            renewAt: data.renewAt.toDate(),
                            createdAt: data.createdAt.toDate(),
                        } as DataStore.LicenceStoreDataWithId);
                }
                return acc;
            }, [] as DataStore.LicenceStoreDataWithId[]))

export const getUserGroups = (cid:Company.Id, uid:User.id):Promise<DataStore.UserGroupStoreData[]> => 
    getUserRef(cid, uid)
        .collection('access')
        .doc('groups')
        .get()
        .then(doc => {
            if(!doc.exists) {
                return [];
            }
            const data = doc.data();
            return data ? Object.values(data as DataStore.UserGroupStoreData) : [];
        });
export const getUserModels = (cid:string, uid:string) => 
    getUserRef(cid, uid)
        .collection('access')
        .doc('models')
        .get()
        .then(doc => {
            if(!doc.exists) {
                return [];
            }
            return Object.entries(doc.data()!)
                .map(([id, value]) => ({id, name: value.name})) as IModel[];
        });

export const subscribeUserModels = (cid:string, uid:string, callback) => 
    getUserRef(cid, uid)
        .collection('access')
        .doc('models')
        .onSnapshot(snapshot => {
            callback(snapshot);
        }) 

export const addModel:DataStore.addModel = async ({companyId, id, data}) => {
    const promisesToWait = [] as Promise<any>[];
    const modelRef = getModelRef(companyId, id);
    await modelRef.set(data, { merge: true });
    promisesToWait.push(modelRef
        .collection('access')
        .doc('groups')
        .set({name: 'idle'}));
    promisesToWait.push(modelRef
        .collection('access')
        .doc('users')
        .set({[data.uploadOwner]: {
            levels: ['read_only', 'uploader']
        }}))

    promisesToWait.push(getUserRef(companyId, data.uploadOwner)
        .collection('access')
        .doc('models')
        .set({[id]: {name: data.name}}, {merge: true}))
    return Promise.all(promisesToWait).then(_ => modelRef.id);
};

export const addMetrics = async ({companyId, modelId, id, data}) => {
    const promisesToWait = [] as Promise<any>[];
    const modelRef = getModelRef(companyId, modelId);
    await modelRef
        .collection('metrics')
        .doc(id)
        .set(data, { merge: true });

    promisesToWait.push(modelRef
        .collection('access')
        .doc('users')
        .set({[data.uploadOwner]: {
            levels: ['read_only', 'uploader']
        }}))

    promisesToWait.push(getUserRef(companyId, data.uploadOwner)
        .collection('access')
        .doc('metrics')
        .set({[id]: {name: data.name}}, {merge: true}))
    return Promise.all(promisesToWait).then(_ => modelRef.id);
};

export const createCompany:DataStore.addCompany = async ({name}) => {
    await adminService.createCompany({name});
    return '';
};

export const createLicence:DataStore.addLicence = async ({companyId, name, users, renewAt}) => {
    const licenceRef = getLicenceRef(companyId);
    await licenceRef.set({
        companyId,
        name,
        users,
        renewAt,
        createdAt: new Date()
    });
    return licenceRef.id;
};
export const updateLicence:DataStore.updateLicence = async ({licenseId, companyId, name, users, renewAt}) => {
    const licenceRef = getLicenceRef(companyId, licenseId);
    await licenceRef.set({
        id: licenseId,
        companyId,
        name,
        users,
        renewAt
    }, {merge: true});
    return licenceRef.id;
};
export const deleteLicence = async (companyId:Company.Id, id:Licence.id) => {
    const licenceRef = getLicenceRef(companyId, id);
    licenceRef.delete();
};

interface IUploadFileMetadata {
    uploadOwner: string;
    uploadTime?: number;
}

export const uploadModel = async (companyId:string, file: File, metadata: IUploadFileMetadata) => {
    try {
        const fileRef = getModelRef(companyId);
        const snapshot = await storageUploadModel(companyId, file, {id: fileRef.id});
        if(snapshot){
            const {name, type} = file;
            const {size, fullPath, timeCreated, updated} = snapshot.metadata;
            // const url = await snapshot.ref.getDownloadURL();
            return addModel({companyId, id: fileRef.id, data: {
                name,
                size,
                type,
                path: fullPath,
                // url,
                createdAt: new Date(timeCreated),
                updatedAt: new Date(updated),
                uploadedAt: new Date(),
                ...metadata
            }});
        }else {
            getModelRef(fileRef.id).delete();
            throw new Error('There is no snapshot');
        }
    }
    catch(err) {
        console.log(`Trying to upload model. ${err}`);
    }
}

export const uploadMetrics = async (companyId:string, modelId:string, file: File, metadata: IUploadFileMetadata) => {
    try {
        const fileRef = getModelRef(companyId);
        const snapshot = await storageUploadMetrics(companyId, modelId, fileRef.id, file, {id: fileRef.id});
        if(snapshot){
            const {name, type} = file;
            const {size, fullPath, timeCreated, updated} = snapshot.metadata;
            // const url = await snapshot.ref.getDownloadURL();
            return addMetrics({companyId, modelId, id: fileRef.id, data: {
                name,
                size,
                type,
                path: fullPath,
                // url,
                createdAt: new Date(timeCreated),
                updatedAt: new Date(updated),
                uploadedAt: new Date(),
                ...metadata
            }});
        }else {
            getModelRef(fileRef.id).delete();
            throw new Error('There is no snapshot');
        }
    }
    catch(err) {
        console.log(`Trying to upload model. ${err}`);
    }
}


export default {
    getUserRef,
    getUserDataByUID,

    getAllUsers,
    getAllGroups,
    getUserGroups,

    uploadModel,
    CONSTANTS: DATABASE_COLLECTION_NAMES
}


// for debug only
if (window.location.hostname === "localhost") {
    console.log("[FIRESTORE]: DEBUG MODE!");
    // firebase
    //     .firestore()
    //     .settings({
    //         host: "localhost:5002",
    //         ssl: false,
    //         // experimentalForceLongPolling: true
    //     })

    // window.firebase = firebase;
        
    // firebase
    //     .firestore
    //     .setLogLevel('debug');
}