import React, { useCallback, useState } from 'react';
import './index.css';
import FileUploader from '../file-uploader';
import { dataService } from '../../services';
import { IAppState } from 'reducers';
import { useSelector, useDispatch } from 'react-redux';
import { getUsersList } from 'utils/selectors';
import { hide } from 'actions/modals';
import {getUsersList as getUsersListAction} from 'actions/admin';
import { toast } from 'react-toastify';

export default function UploadModal({ uid }) {
    const state = useSelector((state: IAppState) => state);
    const dispatch = useDispatch();
    const user = getUsersList(state).find(user => user.uid === uid)!;
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState<File[]>([]);

    const onUploaded = useCallback(
        function () {
            setLoading(true);
            dataService
                .uploadModel(user.companyId, files[0], { uploadOwner: uid })
                .then(() => {
                    dispatch(getUsersListAction({companyId: user.companyId}))
                    dispatch(hide());
                })
                .catch(() => {
                    toast.error('Something went wrong.');
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [uid, user.companyId, dispatch, files],
    );

    return (
        <div id="upload-modal">
            <FileUploader
                onUploaded={onUploaded}
                loading={loading}
                onAcceptedFilesChanged={files => setFiles(files)}
            />
        </div>
    );
}
