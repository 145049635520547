import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import './index.css';

import Button from '../button';
import LabeledInput from '../labeled-input';
import { addCompany } from '../../actions/admin';
import { hide } from 'actions/modals';

export default function AddCompanyModal() {
    const [companyName, setCompanyName] = useState('');

    const dispatch = useDispatch();
    const onButtonClick = useCallback(() => {
        dispatch(addCompany({
            name: companyName, 
        }))
        dispatch(hide());
    }, [dispatch, companyName]);

    return (
        <div id='add-company-modal'>
            <h2 className='add-company-modal_title'>Add company</h2> 
            <div className='add-company-modal_selectors-section'>
                <LabeledInput 
                    label='NAME'
                    onChange={(event) => setCompanyName(event.currentTarget.value)}
                />
            </div>
            <Button onClick={onButtonClick} primary>Done</Button>
        </div>
    );
}

