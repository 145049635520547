import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LabeledInput from '../labeled-input';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { hide } from '../../actions/modals';
import AppIcon from '../app-icon';
import { toast } from 'react-toastify';
import { sendContactSupportRequest } from '../../services/firebase/func';
import { IAppState } from 'reducers';

const ContactUsSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Please use correct name')
        .max(70, 'The name is too long.')
        .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    companyName: Yup.string()
        .min(2, 'Please use correct company name')
        .max(70, 'The company name is too long.')
        .required('Required'),
    question: Yup.string()
        .min(2, 'The question is too short.')
        .max(1000, 'The question is too long.')
        .required('Required'),
});

export default function ContactSupportModal() {
    const [isSent, setIsSent] = useState(false);
    const { user, model } = useSelector(({ user, model }: IAppState) => ({ user, model }));
    const dispatch = useDispatch();
    const closePopup = () => dispatch(hide());

    const contactUs = useFormik({
        validationSchema: ContactUsSchema,
        initialValues: {
            name: user.name,
            companyName: user.companyName,
            email: user.email,
            question: '',
            to: model.supportEmail,
        },
        onSubmit: data => {
            sendContactSupportRequest(data)
                .then(_ => {
                    setIsSent(true);
                })
                .catch(_ => {
                    toast.error('Something went wrong.');
                });
        },
    });
    if (isSent) {
        return (
            <div id="contact-support-modal" className="success">
                <AppIcon iconName="success" className="success-icon" />
                <div className="success-message">
                    <h2>Message successfully sent</h2>
                </div>
                <div className="submit-button">
                    <button className="button primary" onClick={closePopup}>
                        OK
                    </button>
                </div>
            </div>
        );
    }
    return (
        <div id="contact-support-modal">
            <h2>Contact Support</h2>

            <div className="form-holder">
                <form onSubmit={contactUs.handleSubmit}>
                    <div className="form-inputs">
                        <LabeledInput
                            name="name"
                            iconName="user"
                            label="name"
                            value={contactUs.values.name}
                            error={contactUs.errors.name}
                            onChange={contactUs.handleChange}
                        />
                        <LabeledInput
                            name="companyName"
                            iconName="company"
                            label="company name"
                            value={contactUs.values.companyName}
                            error={contactUs.errors.companyName}
                            onChange={contactUs.handleChange}
                        />
                        <LabeledInput
                            name="email"
                            type="email"
                            iconName="mail"
                            label="email"
                            value={contactUs.values.email}
                            error={contactUs.touched.email && contactUs.errors.email}
                            onChange={contactUs.handleChange}
                        />
                        <div className="question-wrapper">
                            <div className="label-container">
                                <div className="label">Question</div>
                                {contactUs.errors.question && (
                                    <span className="question-error">
                                        {contactUs.errors.question}
                                    </span>
                                )}
                            </div>
                            <textarea
                                placeholder="Type here..."
                                rows={4}
                                name="question"
                                onChange={contactUs.handleChange}
                                value={contactUs.values.question}
                            />
                        </div>
                    </div>
                    <div className="submit-button">
                        <button
                            className="button primary"
                            type="submit"
                            disabled={
                                (!contactUs.isValid && contactUs.dirty) || contactUs.isSubmitting
                            }
                        >
                            Send message
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
