import React from 'react';
import { useSelector } from 'react-redux';
import LevelOneEntity from '../level-one-entity';
import './index.css';
import groupBy from 'lodash/groupBy';
import { IAppState } from '../../reducers';
import { makeGetCapabilityById } from '../../utils/selectors'

export default function CapabilitiesDashboard() {
    const views = useSelector(({ capabilities }: IAppState) => capabilities.views);
    const state = useSelector((state: IAppState) => state);
    const selectedScenarioData = useSelector(({ scenarios }: IAppState) => scenarios.list.find(_ => _.scenarioID === scenarios.selectedId));
    const overlayAll = !!selectedScenarioData?.overlayAll;

    const selectedScenario = useSelector(({ scenarios }: IAppState) => scenarios.selected);
    const isScenarioActive = useSelector(({ scenarios }: IAppState) => scenarios.active);

    const getCapabilityById = makeGetCapabilityById();

    const firstLevelGroup = groupBy(views, 'L1ID');

    let coreCapabilities: JSX.Element[] = [];
    let enablingCapabilities: JSX.Element[] = [];

    for (let L1ID in firstLevelGroup) {
        const view = firstLevelGroup[L1ID][0];
        const capability = getCapabilityById(state, view.L1ID);
        const component = <LevelOneEntity
            key={`${view.L1ID}_${view.capID}_${view.metricType}`}
            type={view.L1Type}
            l1Id={view.L1ID}
            name={view.L1Name}
            views={firstLevelGroup[L1ID]}
        />;

        if (isScenarioActive && !overlayAll) {
            const visibleCapabilities = selectedScenario.capabilityIds || [];
            const levelTwoCapabilities = firstLevelGroup[L1ID].some(view => visibleCapabilities.includes(view.capID))
            if (!levelTwoCapabilities) {
                continue;
            }
        }

        if (capability && capability!.type === 'Core') {
            coreCapabilities.push(component)
        } else {
            enablingCapabilities.push(component);
        }
    }

    const core = <div className='grid-container'>{!!coreCapabilities.length && coreCapabilities}</div>
    const enabling = <div className='grid-container'>{!!enablingCapabilities.length && enablingCapabilities}</div>

    return (
        <div id='capabilities-dashboard'>
            {core}
            {!!coreCapabilities.length && !!enablingCapabilities.length && <hr/>}
            {enabling}
        </div>
    )
}
