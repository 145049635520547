import { GROUPS } from '../constants/action-types'
import { IActionGetAllModels } from '../actions/groups';
import { ActionStatus } from '../constants/action-statuses';

export interface IModel {
	id: string;
	name: string;
	metrics?: any[]
}

export interface IModelsState {
	list: IModel[]
}

export const defaultState: IModelsState = {
    list: []
};

export default (state: IModelsState = defaultState, action: IActionGetAllModels): IModelsState => {
	switch (action.type) {
        case GROUPS.GET_ALL_MODELS:
			switch(action.payload.status) {
				case ActionStatus.SUCCESS:
					return {...state, list: action.payload.response};
			}
			return state;
		default:
			return state;
	}
};