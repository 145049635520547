import React from 'react';

import './index.css';
import { useSelector } from 'react-redux';
import { IAppState } from '../../reducers';
import { IViewMetricItem } from '../../actions/filters';
import MetricBar from '../metric-bar';

export default function MetricBarsPane () {
    const {list, viewMetricsMapping} = useSelector(({ filters }: IAppState) => filters);

    const viewFilter = list.find(filter => filter.label === 'VIEW');
    const metricFilter = list.find(filter => filter.label === 'METRIC');
    if(!viewFilter || !metricFilter) {
        return null;
    }
    const activeViewFilterSelection = viewFilter.selections[viewFilter.selectionId];
    const activeMetricFilterSelection = metricFilter.selections[metricFilter.selectionId];

    if(!activeMetricFilterSelection || !activeViewFilterSelection) {
        return null;
    }

    const viewMetricSubTypes = viewMetricsMapping[activeViewFilterSelection]
        .filter(view => view.metricType === activeMetricFilterSelection)
        .reduce((acc:Record<string,IViewMetricItem[]>, view) => {
            if(acc[view.metricSubType] === undefined){
                acc[view.metricSubType] = []
            }
            acc[view.metricSubType].push(view);
            return acc;
        }, {});

    return (
        <div className='metric-bars-pane_container'>
            {Object.keys(viewMetricSubTypes).map(subType => <MetricBar title={subType} subType={subType} key={subType}/>)}
        </div>
    )
}
