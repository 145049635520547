import React, { useState, useCallback, useEffect } from 'react';
import './index.css';
import Button from '../button';
import LabeledInput from '../labeled-input';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { getCompaniesList, updateLicence } from '../../actions/admin';
import { Multiselect } from 'multiselect-react-dropdown';

import 'react-datepicker/dist/react-datepicker.css';
import { dataService } from '../../services';
import { IUser } from '../users-list';
import { IAppState } from '../../reducers';
import { getUsersList } from '../../utils/selectors';
import { getUsersList as getUsersListAction } from '../../actions/admin';
import { User, Company, Licence, DataStore } from '../../services/dataStoreInterfaces';
import { hide } from '../../actions/modals';
import useLicencesSubscription from '../../hooks/useLicencesSubscribtion';

interface Props {
    licenseId: Licence.id;
    companyId: Company.Id;
}

export default function EditLicenceModal({ licenseId, companyId }: Props) {
    const license = useLicencesSubscription([companyId]).find(
        (license: DataStore.LicenceStoreDataWithId) => license.id === licenseId,
    );

    const state = useSelector((state: IAppState) => state);
    const [renewAt, setRenewAt] = useState(license?.renewAt!);
    const [licenceName, setLicenceName] = useState(license?.name!);

    const usersList = getUsersList(state);

    const [addedUsers, setAddedUsers] = useState<IUser[]>([]);
    const [allUsers, setAllUsers] = useState<IUser[]>([]);

    const dispatch = useDispatch();
    const onButtonClick = useCallback(() => {
        dispatch(
            updateLicence({
                companyId,
                name: licenceName,
                users: addedUsers.map(user => user.uid),
                renewAt,
                licenseId,
            }),
        );
        dispatch(hide());
    }, [dispatch, licenceName, addedUsers, companyId, renewAt, licenseId]);

    useEffect(() => {
        setAddedUsers(license?.users
            .map(userId => usersList.find(user => user.uid === userId)!)
            .filter(Boolean) || []);
    }, [license, usersList]);

    const onAddedUsersUpdated = useCallback(selectedList => setAddedUsers(selectedList), []);

    useEffect(() => {
        (async () => {
            const email: User.email = 'emails';
            const allUsers = await dataService.getAllUsers(companyId);
            const result = await Promise.all(
                allUsers.map(async user => {
                    const groups = await dataService.getUserGroups(email, user.uid);
                    const models = await dataService.getUserModels(companyId, user.uid);
                    return { ...user, groups, models };
                }),
            );
            setAllUsers(result);
        })();
    }, [companyId]);

    useEffect(() => {
        dispatch(getUsersListAction({ companyId }));
    }, [companyId, dispatch]);

    useEffect(() => {
        dispatch(getCompaniesList({}));
    }, [dispatch]);

    return (
        <div id="add-licence-modal">
            <h2 className="add-licence-modal_title">Edit Licence</h2>
            <div className="add-licence-modal_selectors-section">
                <LabeledInput
                    label="NAME"
                    placeholder="Type here"
                    value={licenceName}
                    onChange={event => {
                        setLicenceName(event.currentTarget.value);
                    }}
                />

                <div className="add-licence-modal_field-title">ASSIGN USER</div>
                <div className="add-licence-modal_users">
                    <Multiselect
                        options={allUsers}
                        selectedValues={addedUsers}
                        onSelect={onAddedUsersUpdated}
                        onRemove={onAddedUsersUpdated}
                        displayValue="email"
                        showCheckbox
                        closeOnSelect={false}
                        avoidHighlightFirstOption
                        placeholder="Assign people"
                    />
                </div>
                <div className="add-licence-modal_field-title">RENEWAL DATE</div>
                <div className="add-license-modal_date">
                    <DatePicker selected={renewAt} onChange={setRenewAt} />
                </div>
            </div>
            <Button onClick={onButtonClick} primary>
                Done
            </Button>
        </div>
    );
}
