export enum GROUPS {
    GET_ALL_GROUPS = 'GET_ALL_GROUPS',
    GET_ALL_MODELS = 'GET_ALL_MODELS',
}

export enum UserGroups {
    CLIENT_ADMIN = 'Client admin',
    LPFM_SUPPORT = 'LPFM support',
    LPFM_ADMIN = 'LPFM admin',
    EDITOR = 'Editor',
    APPROVER = 'Approver',
    READ_ONLY = 'Read-only',
}