import React, { useState, useCallback } from 'react';
import './index.css';
import AppIcon from '../app-icon';
import { useDropzone } from 'react-dropzone';
import Button from '../button';
import classnames from 'classnames';

const ACCEPTABLE_FILE_EXTENSIONS = ['.xlsm', '.xlsx'];
const ACCEPT_MULTIPLE_FILES_UPLOAD = false;

interface Props {
    loading?: boolean;
    onUploaded?: (this: FileReader, ev: ProgressEvent<FileReader>) => any;
    onAcceptedFilesChanged?: (acceptedFiles: File[]) => any;
    titleLabel?: string; 
    buttonLabel?: string; 
}

export default function FileUploader({
    onUploaded = () => {},
    onAcceptedFilesChanged = () => {},
    loading,
    titleLabel = 'Please upload your model',
    buttonLabel = 'Upload model',
}: Props) {
    const [selectedFile, setSelectedFile] = useState<File>();
    const [hoveringOverDropZone, setHoveringOverDropZone] = useState(false);

    const unSelectFile = () => setSelectedFile(undefined);
    const onDragEnter = () => setHoveringOverDropZone(true);
    const onDragLeave = () => setHoveringOverDropZone(false);
    const onDrop = useCallback(
        acceptedFiles => {
            setSelectedFile(acceptedFiles[0]);
            onDragLeave();
            onAcceptedFilesChanged(acceptedFiles);
        },
        [onAcceptedFilesChanged],
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDragEnter,
        onDragLeave,
        accept: ACCEPTABLE_FILE_EXTENSIONS,
        multiple: ACCEPT_MULTIPLE_FILES_UPLOAD,
    });

    const uploadFile = () => {
        const reader = new FileReader();
        reader.onload = onUploaded;
        reader.readAsBinaryString(selectedFile!);
    };

    const renderDropZone = () => {
        if (!selectedFile) {
            return (
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                        Drag and drop or <span>browse</span>
                    </p>
                </div>
            );
        }
        const size = Math.round(selectedFile.size / 1024);
        return (
            <div className="selected-file">
                <div className="csv-icon">
                    <AppIcon iconName="csv" />
                </div>
                <div className="file-info">
                    <div className="file-name">{selectedFile.name}</div>
                    <div className="file-size">{size} KB</div>
                </div>
                <div className="trash-icon hoverable" onClick={unSelectFile}>
                    <AppIcon iconName="trash" />
                </div>
            </div>
        );
    };
    const dndClassNames = classnames('dnd-section', {
        'file-uploaded': selectedFile,
        'hovering-over': hoveringOverDropZone,
    });
    return (
        <div className="file-uploader">
            <h1>{titleLabel}</h1>
            <p className="upload-description"></p>

            <section className={dndClassNames}>{renderDropZone()}</section>
            <Button primary onClick={uploadFile} disabled={!selectedFile || loading}>
                {buttonLabel}
            </Button>
        </div>
    );
}
