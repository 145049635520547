import React, { useCallback, useState } from 'react';
import './index.css';
import FileUploader from '../file-uploader';
import MetricsParser from 'readers/metricsParser';
import { useDispatch } from 'react-redux';
import { updateViewMetrics } from 'actions/capabilities';
import { hide } from 'actions/modals';
import { CURRENT_METRICS_STORAGE_KEY } from 'constants/file-content';
import { dataService } from 'services';
import { showSuccessToast, showErrorToast } from 'components/toast-with-icon';

export default function UploadMetricModal({ uid, modelId, companyId }) {
    const [acceptedFiles, setAcceptedFiles] = useState([] as File[]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const parseFile = useCallback(
        file => {
            const { viewMetrics } = MetricsParser(file);

            dispatch(updateViewMetrics(viewMetrics));
            dispatch(hide());
        },
        [dispatch],
    );

    const onAcceptedFilesChanged = useCallback(setAcceptedFiles, []);

    const onUploaded = async function (this: FileReader) {
        await dataService
            .uploadMetrics(companyId, modelId, acceptedFiles[0], { uploadOwner: uid })
            .then(() => {
                dispatch(hide());
                showSuccessToast('Metric data uploaded successfully');
            })
            .catch(() => showErrorToast('Error uploading metric data'))
            .finally(() => setLoading(false));
        try {
            parseFile(this.result);
            localStorage.setItem(CURRENT_METRICS_STORAGE_KEY, encodeURI(this.result as string));
        } catch(e) {
            showErrorToast('Error uploading metric data');
        }
    };

    return (
        <div id="upload-modal">
            <FileUploader
                onUploaded={onUploaded}
                onAcceptedFilesChanged={onAcceptedFilesChanged}
                loading={loading}
                titleLabel='Please upload your metric'
                buttonLabel='Upload metric'
            />
        </div>
    );
}
