import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './index.css';

import { IAppState } from 'reducers';
import { IModel } from 'reducers/model';
import { getUsersList, getCompaniesList } from 'actions/admin';
import { getUserAdminRoleStatus } from 'utils/selectors';
import TableHeader from 'components/table-header';
import { DataStore } from 'services/dataStoreInterfaces';
import CompaniesListItem from 'components/companies-list-item';
import useCompaniesSubscription from 'hooks/useCompaniesSubscribtion';
import useSortableData from 'hooks/useSortableData';

export interface IUser extends DataStore.UserStoreDataWithId {
    groups: DataStore.UserGroupStoreData[];
    models: IModel[];
}

export default function CompaniesList() {
    const companiesHash = useCompaniesSubscription();
    const dispatch = useDispatch();
    const state = useSelector((state: IAppState) => state);
    const isUserSuperAdmin = useSelector(getUserAdminRoleStatus);
    const companies = useMemo(() => Object.values(companiesHash), [companiesHash]);

    const { items, requestSort, sortConfig } = useSortableData(companies);

    useEffect(() => {
        if (isUserSuperAdmin) {
            dispatch(getCompaniesList({}));
        } else {
            dispatch(getUsersList({ companyId: state.user.companyId }));
        }
    }, [dispatch, isUserSuperAdmin, state.user.companyId]);

    return (
        <div className="companies-list-container">
            <div className="companies-list">
                <div></div>
                <TableHeader
                    name="name"
                    label="NAME"
                    sortConfig={sortConfig}
                    onClick={() => requestSort('name')}
                />
                {items.map(company => (
                    <CompaniesListItem key={company.id} id={company.id} name={company.name} />
                ))}
            </div>
        </div>
    );
}
