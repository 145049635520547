import React from 'react';
import { ISortableConfig } from 'hooks/useSortableData';
import cx from 'classnames';
import './index.css';
import AppIcon from 'components/app-icon';

export interface ITableHeader {
    name?: string;
    label: string;
    sortConfig?: ISortableConfig;
    onClick?: () => void;
}

export default function TableHeader({ name, label, sortConfig, onClick }: ITableHeader) {
    const getClassNamesFor = () => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
        <div onClick={onClick} className={cx('table-header', getClassNamesFor(), {[label]: label})}>
            <div className="table-header_content">
                <span className="table-header_label">{label}</span>
                {name && <AppIcon iconName="sort" />}
            </div>
        </div>
    );
}