import React, { SyntheticEvent } from 'react';
import { buildColorStyles } from '../../utils';
import { useSelector } from 'react-redux';
import './index.css';
import Tooltip from 'rc-tooltip';
import { IView, VisualTypes } from '../../interfaces';
import { IAppState } from '../../reducers';
import { getAppColors } from '../../utils/selectors';
import MetricStateLabel from '../metric-state-label';
import FormattedText from '../formatted-text';
import classNames from 'classnames';

interface Props {
    view: IView;
    subject: {
        name: string;
        fullName: string;
        description: string;
        value: string;
    };
    visualType: VisualTypes;
    onClick(event: SyntheticEvent): void;
}

const emptyObj = {};

export default function LevelThreeLabel({
    view,
    subject: { name, fullName, description, value },
    visualType,
    onClick,
}: Props) {
    const state = useSelector((state: IAppState) => state);
    // const getMetricConfigByView = makeGetMetricConfigByView(view);
    // const metric = getMetricConfigByView(state);

    // if (!metric) {
    //     return null;
    // }

    const getColors = getAppColors(state);
    const metricColour = buildColorStyles(getColors(view.colour));

    const onClickHandler = (event: SyntheticEvent) => {
        onClick(event);
    };

    const isMetricGroup = visualType === VisualTypes.metricGroup;
    const isMetricGroupColor = visualType === VisualTypes.metricGroupColour;
    const labelStyle = isMetricGroup || isMetricGroupColor ? emptyObj : metricColour;

    return (
        <Tooltip
            trigger={'hover'}
            placement={'bottom'}
            transitionName={'appear'}
            destroyTooltipOnHide={{ keepParent: false }}
            overlay={() => {
                return (
                    <div className="label-item-popover">
                        <MetricStateLabel view={view} showActualValue />
                        <div className="label-full-name">{fullName}</div>
                        <div className="label-description">
                            <FormattedText str={view.commentary || description} />
                        </div>
                    </div>
                );
            }}
        >
            <div
                onClick={onClickHandler}
                className={classNames('level-three-label_container', {
                    'one-per-line': false,
                })}
            >
                {name !== ' ' && (
                    <div className="level-three-label_name" style={labelStyle}>
                        {name}
                    </div>
                )}
                {(isMetricGroup || isMetricGroupColor) && (
                    <div className="level-three-label_value" style={metricColour}>
                        {value}
                    </div>
                )}
                {isMetricGroupColor && (
                    <div className="level-three-label_value" style={metricColour}>
                        {view.colourLabel}
                    </div>
                )}
            </div>
        </Tooltip>
    );
}
