import React from 'react';
import './index.css';
import { hide } from '../../actions/modals';
import { useSelector, useDispatch } from 'react-redux';
import { ModalTypes } from '../../constants/modals';
import SystemModal from '../system-modal';
import CapabilityModal from '../capability-modal';
import ActorModal from '../actor-modal';
import ContactSupportModal from '../contact-support-modal';
import GenericModal from '../generic-modal';
import AppIcon from '../app-icon/index';
import { IAppState } from '../../reducers';
import InviteUserModal from '../invite-user-modal';
import AddLicenceModal from '../add-licence-modal';
import AddCompanyModal from '../add-company-modal';
import UploadModal from '../upload-modal';
import EditUserModal from '../edit-user-modal';
import EditLicenceModal from '../edit-license-modal';
import UploadMetricModal from '../upload-metric-modal';
import ReactModal from 'react-modal';
import cx from 'classnames';

export default function GeneralModal() {
    const dispatch = useDispatch();
    const {
        type,
        isOpen,
        modalData: { uid, system, view, capability, actor, data, companyId, modelId },
    } = useSelector(({ modals }: IAppState) => modals);

    const hideModal = () => dispatch(hide());
    const renderModalByType = (type?: ModalTypes) => {
        switch (type) {
            case ModalTypes.SYSTEM:
                return <SystemModal system={system} view={view} />;
            case ModalTypes.CAPABILITY:
                return <CapabilityModal capability={capability} />;
            case ModalTypes.ACTOR:
                return <ActorModal actor={actor} />;
            case ModalTypes.GENERAL:
                return <GenericModal data={data} view={view}/>;
            case ModalTypes.INVITE_USER:
                return <InviteUserModal />;
            case ModalTypes.ADD_LICENCE:
                return <AddLicenceModal />;
            case ModalTypes.ADD_COMPANY:
                return <AddCompanyModal />;
            case ModalTypes.UPLOAD_MODAL:
                return <UploadModal uid={uid} />;
            case ModalTypes.EDIT_USER_MODAL:
                return <EditUserModal uid={uid} />;
            case ModalTypes.EDIT_LICENSE_MODAL:
                return <EditLicenceModal licenseId={uid} companyId={companyId} />;
            case ModalTypes.CONTACT_SUPPORT:
                return <ContactSupportModal />;
            case ModalTypes.METRICS_UPLOAD:
                return <UploadMetricModal uid={uid} modelId={modelId} companyId={companyId} />;
            default:
                return null;
        }
    };
    const appElement = document.getElementById('root');
    if (!appElement) {
        return null;
    }
    return (
        <ReactModal
            appElement={appElement}
            isOpen={isOpen}
            className={cx('modal-portal', type?.toLowerCase())}
            overlayClassName="modal-overlay"
            onRequestClose={hideModal}
        >
            <div id="modal-container">
                <div className="close-button hoverable" onClick={hideModal}>
                    <AppIcon iconName="close" />
                </div>
                {isOpen && renderModalByType(type)}
            </div>
        </ReactModal>
    );
}
