import React, { Component } from 'react';

import './auth.css';

export default function auth(OriginalComponent: any) {
    return class extends Component {
       // make some enhancements
       // ...
        render() {
          // return original component with additional props
            return (
                <div id='auth-page-container'>
                    <div className='image'>
                        <img src={'/welcome-landing.png'} alt=""/>
                    </div>
                    <div className='auth-form-container'>
                        <OriginalComponent {...this.props} />
                    </div>
                </div>   
            )
        }
    }
}
