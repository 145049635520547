import React, { ReactElement } from 'react';
import './index.css';
import AppIcon from '../app-icon';
import cx from "classnames";

interface Props {
    iconName?: string;
    label: string;
    name?: string;
    required?: boolean;
    placeholder?: string;
    rounded?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    type?: string;
    autoComplete?: string;
    error?: string|boolean|ReactElement;
}

export default function LabeledInput({
    iconName,
    required,
    rounded,
    name,
    label,
    placeholder,
    onChange,
    value,
    type,
    autoComplete,
    error,
}: Props) {
    return (
        <div className="labeled-input">
            {iconName && <AppIcon iconName={iconName} />}
            <div className="labels-container">
                <span className="label">
                    <span>{label}</span>
                    {required && <span className="label-required">*</span>}
                </span>
                {error && <span className="error">{error}</span>}
            </div>
            <input
                className={cx('input', { error, rounded })}
                autoComplete={autoComplete}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                name={name}
                type={type}
            />
        </div>
    );
};