import { AUTH, APP, USER } from '../constants/action-types'
import { ActionStatus } from '../constants/action-statuses'
import { IAuthAction } from '../actions/auth';
import { IModel } from './models';
import { DataStore } from 'services/dataStoreInterfaces';
import { IAdminAction } from 'actions/admin';

export interface IUserState {
    uid: string;
    email: string;
    name: string;
    companyName: string;
    companyId: string;
    roles: any;
    groups: DataStore.UserGroupStoreData[];
    models: IModel[];
}

export const defaultState: IUserState = {
    uid: '',
    email: '',
    name: '',
    companyName: '',
    companyId: '',
    roles: {},
    groups: [],
    models: []
};

export default (state: IUserState = defaultState, action: IAuthAction | IAdminAction): IUserState => {
	switch (action.type) {
        case AUTH.REQUEST_USER_AUTH:
        case AUTH.SIGN_UP_REQUEST:
        case AUTH.SIGN_IN_REQUEST:
            switch(action.payload.status) {
                case ActionStatus.SUCCESS:
                    const {uid, roles, groups, models, email, name, companyName, companyId} = action.payload.response;
                    return {
                        ...state,
                        roles: {...state.roles, ...roles},
                        groups,
                        models,
                        email,
                        name, 
                        companyName,
                        companyId,
                        uid
                    };
                default:
                    return state;
            }
        case USER.MODELS_CHANGED:
            console.log('MODELS_CHANGED')
            return {
                ...state,
                models: action.payload
            }
        case APP.RESET_EVERYTHING:
            return defaultState;
		default:
			return state;
	}
};