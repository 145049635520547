export enum FILTERS {
    SELECTION_CHANGED = 'SELECTION_CHANGED',
    VIEW_METRICTS_MAPPING_SET = 'VIEW_METRICTS_MAPPING_SET',
    CALCULATE_METRICS_FILTER_SOURCE = 'CALCULATE_METRICS_FILTER_SOURCE',
    SET_DEFAULT_FILTER_SELECTION = 'SET_DEFAULT_FILTER_SELECTION',
    SET_LOCATIONS_FILTER_SOURCE = 'SET_LOCATIONS_FILTER_SOURCE',
    SET_STATES_FILTER_SOURCE = 'SET_STATES_FILTER_SOURCE',
    SET_PRODUCT_FILTER_SOURCE = 'SET_PRODUCT_FILTER_SOURCE',
    SET_BUSINESS_FILTER_SOURCE = 'SET_BUSINESS_FILTER_SOURCE',
    SET_CLIENT_FILTER_SOURCE = 'SET_CLIENT_FILTER_SOURCE',
    RESET_ALL = 'RESET_ALL',
    SET_COLORS = 'SET_COLORS',
}