import { useContext, useEffect } from 'react';
import { DataContext } from 'App';
import { dataService } from 'services';

let licencesCache = {};
const useCompaniesSubscription = () => {
    const {companies, setCompanies} = useContext(DataContext)

    useEffect(() => {
        return dataService.subscribeAllCompanies((changes) => {
            !!changes.removed && Object.keys(changes.removed).forEach(id => {
                delete licencesCache[id];
            });
            (!!changes.added) && Object.entries(changes.added).forEach(([id, value]) => {
                licencesCache[id] = {id, ...value};
            });
            (!!changes.modified) && Object.entries(changes.modified).forEach(([id, value]) => {
                licencesCache[id] = {id, ...value};
            });
            setCompanies({...licencesCache});
        })
    }, [setCompanies]);
    
    return companies;
};

export default useCompaniesSubscription;